<template>
  <div class="relative news-event-list-wrap">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div class="content-wrap">
      <div class="content-div">
        <div class="director-div">
          <div>消息與活動</div>
          <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
          <div>更多...</div>
        </div>
        <div class="title" >News And Events</div>
        <div class="sub-div">
          <div class="subtitle">消息與活動</div>
          <div class="date"></div>
        </div>
        <div class="border"></div>

        <div class="tag-wrap">
<!--          <div class="tag-div" :class="[tagIdRef==1 ? 'selected' : '']" @click="tagBtn(1)"># 消息與活動</div>-->
<!--          <div class="tag-div" :class="[tagIdRef==2 ? 'selected' : '']" @click="tagBtn(2)"># 最新消息</div>-->
<!--          <div class="tag-div" :class="[tagIdRef==3 ? 'selected' : '']" @click="tagBtn(3)"># 最新活動</div>-->
<!--          <div class="tag-div" :class="[tagIdRef==4 ? 'selected' : '']" @click="tagBtn(4)"># 活動回顧</div>-->
          <div class="tag-div" v-for="content in tagContent" :key="content" :class="[tagIdRef==content[0] ? 'selected' : '']" @click="tagBtn(content[0])">{{ content[1] }}</div>
        </div>

        <div class="list-wrap">
          <div class="news-div" v-for="content in newsContent" :key="content" @click="routerBtn('/news/'+content[0])">
            <div class="news-wrap">
              <div class="date">{{ content[3] }}</div>
              <div class="img"><img :src='content[5]' v-if="content[5] != '' "> </div>
              <div class="title">{{ content[1] }}</div>
            </div>
          </div>

        </div>

        <div class="page-wrap">
          <div>Page</div>
          <div class="page-num-div">{{ curPageText }}</div>
          <div>Of {{ pageAllText }}</div>
          <div class="arrow" @click="updateData(-1)"> <img src="../assets/news_list/arrow_white.png"> </div>
          <div class="arrow" @click="updateData(1)"> <img src="../assets/news_list/arrow_white.png"></div>
        </div>
      </div>
    </div>


    <div class="footer"></div>

    <menu-bar></menu-bar>
  </div>
</template>

<script>
import "@/assets/css/news_event_list/news-event-list.css"
import MenuBar from "@/components/MenuBar";
import router from "@/router";
import axios from "axios";
import {reactive, ref} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";

export default {
  name: "NewsView",
  components:{
    MenuBar,
    ToolbarComponent,
  },
  setup(){
    window.scrollTo(0,0);

    const allContent = reactive([[]]);
    const newsContent = reactive([]);
    const tagContent = reactive([]);
    const pageAllText = ref("");
    const curPageText = ref("");
    const tagIdRef = ref(-1);

    let curIndex = 0;

    function routerBtn(path){
      router.push(path);
    }

    loadNews(-1);
    loadTag();

    function loadNews(tag){

      let api = (tag==-1) ? 'php/news.php?func=query-no-news' : 'php/news.php?func=query-by-tag';

      axios.post(window.phpPath+api,{
        tagId:tag
      })
          .then( (response) => {
            //console.log(response.request.responseText);

            allContent.splice(0, allContent.length);
            allContent.push([]);

            let curIndex = 0;
            let curCount = 0;
            let json = JSON.parse(response.request.responseText);
            for (var i=0; i< json.length; i++){

              let id = json[i].main_id;
              let title = json[i].title;
              let subtitle = json[i].subtitle;
              let date = json[i].date.replaceAll('-','.');
              let newHidden = (json[i].new==0) ? "hidden":"";
              let img_name = (json[i].img_name == "") ? "" : window.phpPath + "php/upload/" + json[i].img_name;

              if(curCount >= 6){
                curCount = 0;
                allContent.push([]);
                curIndex++;
              }
              let array = [id, title, subtitle, date, newHidden, img_name];
              allContent[curIndex].push(array);
              curCount++;
            }

            pageAllText.value = "" + allContent.length;

            updateData(0);
          })
          .catch( (error) => console.log(error))

    }

    function loadTag(){

      let api = 'php/news.php?func=tag-query';

      axios.get(window.phpPath+api)
          .then( (response) => {
            //console.log(response.request.responseText);

            tagContent.splice(0, tagContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i< json.length; i++){

              let id = json[i].tag_id;
              let tag_name = "# " + json[i].tag_name;

              tagContent.push([]);

              //let array = [id, tag_name];
              tagContent[i].push(id, tag_name);
            }
          })
          .catch( (error) => console.log(error))

    }

    function updateData(count){
      curIndex += count;
      curIndex = Math.min(Math.max(curIndex, 0), allContent.length-1);

      newsContent.splice(0, newsContent.length);

      for (var i=0; i<allContent[curIndex].length; i++){
        newsContent.push(allContent[curIndex][i]);
      }

      curPageText.value = (curIndex+1).toString().padStart(2, '0');
    }

    function tagBtn(tagId){
      curIndex = 0;
      if(tagIdRef.value != tagId){
        tagIdRef.value = tagId;
      }else{
        tagIdRef.value = -1;
      }

      loadNews(tagIdRef.value);
    }

    return{
      routerBtn,
      newsContent,
      updateData,
      pageAllText,
      curPageText,
      tagBtn,
      tagIdRef,
      tagContent,
    }
  }
}
</script>

<style scoped>


</style>
