<template>
  <div class="manage-page-wrap">
    <div v-if="state == 'account'" >
      <div class="login-page">
        <div>登入 </div>
        <div class="account-div">帳號<input id="account-input" v-model="accountRef" type="text"></div>
        <div class="password-div">密碼<input id="password-input"  v-model="passwordRef"  type="password"></div>
        <div class="login-btn" @click="loginBtn">登入</div>
      </div>
    </div>

    <div v-if="state == 'login'" class="info-page">

      <div class="toolbar" style="overflow: auto; overflow-x: hidden">
        <div style="position:relative; width: 85%" >
          <img src="../assets/logo-title.png">
          <div style="position:absolute; right:0; font-size: 0.5vw; font-weight: bold; color: #009F8F; ">後台管理系統</div>
        </div>


        <div class="link-list">
          <div :class="curPage == 'main' ? 'selected':'' " @click="routerBtn('main')">首頁輪播圖</div>
          <div :class="curPage == 'news' ? 'selected':'' " @click="routerBtn('news')">最新消息</div>
<!--          <div :class="curPage == 'activity' ? 'selected':'' " @click="routerBtn('activity')">最新活動</div>-->
          <div :class="curPage == 'news-activity-event' ? 'selected':'' " @click="routerBtn('news-activity-event')">消息與活動</div>
<!--          <div :class="curPage == 'event' ? 'selected':'' " @click="routerBtn('event')">活動回顧</div>-->
          <div :class="curPage == 'memorabilia' ? 'selected':'' " @click="routerBtn('memorabilia')">大事記</div>
          <div :class="curPage == 'pre' ? 'selected':'' " @click="routerBtn('pre')">前期諮詢</div>
          <div :class="curPage == 'charge' ? 'selected':'' " @click="routerBtn('charge')">收費辦法</div>
          <div :class="curPage == 'data-1' ? 'selected':'' " @click="routerBtn('data-1')">進駐申請</div>
          <div :class="curPage == 'data-2' ? 'selected':'' " @click="routerBtn('data-2')">跨領域研究大樓申請</div>
          <div :class="curPage == 'data-3' ? 'selected':'' " @click="routerBtn('data-3')">核心設施申請</div>
          <div :class="curPage == 'contact' ? 'selected':'' " @click="routerBtn('contact')">聯絡資訊</div>
          <div :class="curPage == 'tag' ? 'selected':'' " @click="routerBtn('tag')">Tag標籤管理</div>
          <div :class="curPage == 'seo' ? 'selected':'' " @click="routerBtn('seo')">SEO設定</div>
        </div>
      </div>

      <div class="right-content">

      <div v-if="curPage=='main'"><main-manage></main-manage></div>
      <div v-if="curPage=='news'"><news-manage title-prop="最新消息"></news-manage></div>
<!--      <div v-if="curPage=='activity'"><news-manage title-prop="最新活動"></news-manage></div>-->
      <div v-if="curPage=='news-activity-event'"><news-manage title-prop="消息與活動"></news-manage></div>
<!--      <div v-if="curPage=='event'"><news-manage title-prop="活動回顧"></news-manage></div>-->
      <div v-if="curPage=='memorabilia'"><memorabilia-manage></memorabilia-manage></div>
      <div v-if="curPage=='pre'"><application-manage></application-manage></div>
      <div v-if="curPage=='charge'"><charge-manage></charge-manage></div>
      <div v-if="curPage=='data-1'"><data-manage title-prop="進駐申請" sort-id="0"></data-manage></div>
      <div v-if="curPage=='data-2'"><data-manage title-prop="跨領域研究大樓申請" sort-id="1"></data-manage></div>
      <div v-if="curPage=='data-3'"><data-manage title-prop="核心設施申請" sort-id="2"></data-manage></div>
      <div v-if="curPage=='contact'"><contact-manage></contact-manage></div>
      <div v-if="curPage=='tag'"><tag-manage></tag-manage></div>
      <div v-if="curPage=='seo'"><s-e-o-manage></s-e-o-manage></div>

<!--        <application-manage></application-manage>-->
<!--        <data-manage></data-manage>-->

      </div>

    </div>

  </div>


</template>

<script>
import axios from "axios";
import {nextTick, reactive, ref} from "vue";
import CryptoJS from 'crypto-js'
import NewsManage from "@/components/manage/NewsManage";
import ApplicationManage from "@/components/manage/ApplicationManage";
import DataManage from "@/components/manage/DataManage";
import MemorabiliaManage from "@/components/manage/MemorabiliaManage";
import router from "@/router";
import TagManage from "@/components/manage/TagManage";
import ChargeManage from "@/components/manage/ChargeManage";
import MainManage from "@/components/manage/MainManage";
import ContactManage from "@/components/manage/ContactManage";
import SEOManage from "@/components/manage/SEOManage";
import {Base64} from "js-base64";

export default {
  name: "ManageView",
  components:{
    NewsManage,
    ApplicationManage,
    DataManage,
    MemorabiliaManage,
    TagManage,
    ChargeManage,
    MainManage,
    ContactManage,
    SEOManage,
  },
  setup(){
    const  state = ref("");
    const  curPage = ref("main");

    const accountRef = ref(null);
    const passwordRef = ref(null);


    if(window.managePage != ''){
      curPage.value = window.managePage;
      ///console.log(curPage.value)
      window.managePage = '';
    }

    state.value = "account";

    if(!(localStorage.getItem(Base64.encode('account')) === null && localStorage.getItem(Base64.encode('password'))  === null)){
      accountRef.value = Base64.decode(localStorage.getItem(Base64.encode('account')));
      passwordRef.value = Base64.decode(localStorage.getItem(Base64.encode('password')));
      loginBtn();
    }

    function loginBtn(){
      if(state.value == "account"){
        let account = accountRef.value;
        let password = passwordRef.value;

        axios.post(window.phpPath+'php/admin.php',{
          account:account,
          password:HmacSHA256(password, "admin"),
        })
            .then( (response) => {
              //console.log(response)
              let json = JSON.parse(response.request.responseText);
              if(json.length > 0){
                state.value = "login";


                localStorage.setItem(Base64.encode('account'), Base64.encode(account));
                localStorage.setItem(Base64.encode('password'), Base64.encode(password));
                //console.log(state.value)
                //loadNews();
              }else{
                alert("帳號密碼錯誤");
              }
            })
            .catch( (error) => console.log(error))
      }

    }

    function HmacSHA256(message, secret){
      let hash = CryptoJS.HmacSHA256(message, secret);
      var hashInBase64 = CryptoJS.enc.Hex.stringify(hash);

      return hashInBase64;
    }

    document.onkeydown = function (e) {
      e = e || window.event;
      switch (e.which || e.keyCode) {
        case 13 : //Your Code Here (13 is ascii code for 'ENTER')
          loginBtn();
          break;
      }
    }

    async function routerBtn(page){
      curPage.value = '';
      await nextTick();
      curPage.value = page;
    }




    return{
      curPage,
      state,
      loginBtn,
      accountRef,
      passwordRef,
      routerBtn,
    }
  },
}
</script>

<style scoped src="../assets/css/manage/manage.css">
</style>
<style scoped>

.manage-page-wrap {
  position: relative;
  width: 100%;
}

.manage-page-wrap .info-page{
  position: relative;
  width: 100%;
}

.manage-page-wrap .info-page .right-content{
  position: relative;
  width: 85%;
  margin-left: 15%;
  //background: gray;
}

.manage-page-wrap .toolbar{
  position: fixed;
  top:0;
  left: 0;
  background: rgba(222,242,240, 10%);
  box-shadow: 0px 4px 14px rgb(0 0 0 / 10%);;
  width: 15%;
  height: 100vh;
  z-index: 10;
  padding: 3% 0 0 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manage-page-wrap .toolbar .link-list{
  font-size: 1.2vw;
  font-weight: bold;
  color: #009F8F;

}

.manage-page-wrap .toolbar .link-list > div:nth-child(1){
  margin-top: 35%;
}

.manage-page-wrap .toolbar .link-list > div{
  margin: 13% 0 13% 0;
  width: fit-content;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.manage-page-wrap .toolbar .link-list > div:hover{
  border-bottom: 3px solid #FFF100;
}

.manage-page-wrap .toolbar .link-list .selected{
  border-bottom: 3px solid #FFF100;
}

</style>
