<template>
  <div class="manage-page" style="width: 100%;">
    <!--  前期諮詢  -->
    <div class="title">前期諮詢</div>
    <div class="flex justify-center" v-if="state=='list'">
      <table id="table">
        <tbody id="manage-tbody">
        <tr class="table-title">
          <td>編號</td>
          <td>公司名稱</td>
          <td>聯絡人姓名</td>
          <td>聯絡人職位</td>
          <td>聯絡人手機電話</td>
          <td>聯絡人Email</td>
          <td>填寫日期</td>
<!--          <td>營業項目/發展技術</td>-->
<!--          <td>其他需求</td>-->
          <td>詳細</td>
          <td>刪除</td>
        </tr>

        <tr v-for="(content, index) in applicationContent" :key="content">
          <td>{{ index+1 }}</td>
          <td>{{ content[1] }}</td>
          <td>{{ content[2] }}</td>
          <td>{{ content[3] }}</td>
          <td>{{ content[4] }}</td>
          <td>{{ content[5] }}</td>
          <td>{{ content[9] }}</td>
<!--          <td>{{ content[7] }}</td>-->
          <td :id="'edit-'+content[0]" class="edit-btn" @click="viewBtn(index)">觀看</td>
          <td class="edit-btn" @click="deleteBtn(content[0])">刪除</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center" style="width: 100%;" v-if="state=='view'">

        <div class="flex-list " style="width: 90%; justify-content: center">
          <div class="text title">公司名稱</div><div>{{ company }}</div>
          <div class="text title">聯絡人姓名</div><div>{{ name }}</div>
          <div class="text title">聯絡人職稱</div><div>{{ job }}</div>
          <div class="text title">聯絡人手機電話</div><div>{{ phone }}</div>
          <div class="text title">聯絡人E-mail</div><div>{{ email }}</div>
          <div class="text title">營業項目/發展技術</div><div>{{ tech }}</div>
          <div class="text title">簡述進駐團隊經歷、專長、背景</div><div>{{ experience }}</div>
          <div class="text title">其他需求</div><div>{{ other }}</div>
          <div class="text title">填寫日期</div><div>{{ date }}</div>

          <div class="btn-wrap" style="width:100%; justify-content: center;!important;">
            <div class="back-btn" @click="backBtn">返回</div>
          </div>
        </div>

    </div>

  </div>

</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";

export default {
  name: "ApplicationManage",
  setup(){
    const applicationContent = reactive([[]]);
    const state = ref("list");
    const company = ref('');
    const name = ref('');
    const job = ref('');
    const phone = ref('');
    const email = ref('');
    const tech = ref('');
    const experience = ref('');
    const other = ref('');
    const date = ref('');

    loadApplication();

    function loadApplication(){

      axios.get(window.phpPath+'php/application.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);

            applicationContent.splice(0, applicationContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i < json.length; i++) {
              let id = json[i].id;
              let company = json[i].company;
              let name = json[i].name;
              let job = json[i].job;
              let phone = json[i].phone;
              let email = json[i].email;
              let tech = json[i].tech;
              let experience = json[i].experience;
              let other = json[i].other;
              let date = json[i].date;


              let array = [id, company, name, job, phone, email, tech, experience, other, date];
              applicationContent.push(array);
            }


          })
          .catch( (error) => console.log(error))

    }

    function deleteBtn(id){

      if (window.confirm("確定要刪除嗎?")) {
        axios.post(window.phpPath+'php/application.php?func=delete',{
          id:id
        })
            .then( (response) => {
              //console.log(response)
              loadApplication();
            })
            .catch( (error) => console.log(error))
      }
    }

    function viewBtn(index){
      company.value = applicationContent[index][1];
      name.value = applicationContent[index][2];
      job.value = applicationContent[index][3];
      phone.value = applicationContent[index][4];
      email.value = applicationContent[index][5];
      tech.value = applicationContent[index][6];
      experience.value = (applicationContent[index][7] == "") ? "無":applicationContent[index][7];
      other.value = (applicationContent[index][8] == "") ? "無":applicationContent[index][8];
      date.value = applicationContent[index][9];

      state.value = "view";
    }

    function backBtn(){
      state.value = "list";
      loadApplication();
    }

    return{
      applicationContent,
      deleteBtn,
      state,
      viewBtn,
      company,
      name,
      job,
      phone,
      email,
      tech,
      experience,
      other,
      date,
      backBtn,
    }
  }
}
</script>

<style scoped src="@/assets/css/manage/manage.css">

</style>

<style scoped>

.flex-list >  div{
  margin-top: -3%;
  font-size: 1.1vw;
}

.flex-list .title{
  color: #00ACA0;
  font-size: 1.5vw;
  margin-top: 5%!important;
}

.flex-list >  div:nth-child(1){
  margin-top: 0%;
}


.border{
  width: 100%;
}
</style>
