<template>
  <div class="about-page" id="about-page">
    <div class="semi-wrap">
      <img src="../assets/home/about/half-circle.png">
    </div>

    <a class="space-btn" href="https://assc.sinica.edu.tw/ASSC/login.php?r=2273754140" target="_blank">
      <img src="../assets/home/about/space_btn.png">
      <div class="arrow"><img  src="../assets/home/about/down_arrow.png"></div>
    </a>

    <div class="history-div">
      <div>
        <div>關於中央研究院</div>
        <div>南部育成中心</div>
      </div>
      <div class="history-btn" @click="routerBtn('/memorabilia')">大事記<img src="../assets/arrow_white.png"></div>
    </div>

    <div class="about-logo-div" id="about-logo-div">
        <img src="../assets/home/about/about-logo.png">
    </div>

    <div class="dot-bg-1">
      <img src="../assets/gray-dot.png">
    </div>

    <div class="dot-bg-2">
      <img src="../assets/gray-dot.png">
    </div>


    <div class="about-content-div">
      <div class="content-div" @click="routerBtn('/target-task')">
        <div class="content-img">
          <img src="../assets/home/about/task_mission_img.png" >
        </div>
        <div class="content-text">
          <img src="../assets/home/about/task_mission.png" id="content-text-1">
        </div>
      </div>
      <div class="content-div" @click="routerBtn('/nurturing-environment')">
        <div class="content-text" style="margin-left: 11%">
          <img src="../assets/home/about/space.png" id="content-text-2">
        </div>
        <div class="content-img">
          <img src="../assets/home/about/space_img.png" >
        </div>
      </div>
      <div class="content-div"  @click="routerBtn('/research-facilities')">
        <div class="content-img">
          <img src="../assets/home/about/core_facilities_img.png">
        </div>
        <div class="content-text">
          <img src="../assets/home/about/core_facilities.png" id="content-text-3">
        </div>
      </div>
    </div>

    <div class="dot-bg-3">
      <img src="../assets/green-dot.png">
    </div>

    <div class="dot-bg-4">
      <img src="../assets/green-dot.png">
    </div>

    <div class="dot-bg-5">
      <img src="../assets/green-dot.png">
    </div>


  </div>
</template>

<script>
import "@/assets/css/home/about.css"
import router from "@/router";

export default {
  name: "AboutPage",
  mounted() {

    setTimeout(()=>{
      var observer = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('about-logo-div').classList.add('fade-anim');
          observer.disconnect();
        }
      }, { threshold: [0] });
      observer.observe(document.querySelector("#about-logo-div"));

      var contentText1 = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('content-text-1').classList.add('fade-in');
          contentText1.disconnect();
        }
      }, { threshold: [0] });
      contentText1.observe(document.querySelector("#content-text-1"));

      var contentText2 = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('content-text-2').classList.add('fade-in');
          contentText2.disconnect();
        }
      }, { threshold: [0] });
      contentText2.observe(document.querySelector("#content-text-2"));

      var contentText3 = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('content-text-3').classList.add('fade-in');
          contentText3.disconnect();
        }
      }, { threshold: [0] });
      contentText3.observe(document.querySelector("#content-text-3"));
    }, 10);


  },
  setup(){

    function routerBtn(path){
      router.push(path);
    }

    return{
      routerBtn,
    }
  },
}
</script>

<style scoped>


</style>
