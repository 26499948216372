<template>
  <div class="relative news-view-page">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div class="content-wrap">
      <div class="content-div">
        <div class="director-div">
          <div @click="routerBtn('/')">關於育成中心</div>
          <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
          <div style="color: #3B3938">目標與任務</div>
        </div>
        <div class="title" >Task & Mission</div>
        <div class="sub-div">
          <div class="subtitle" style="font-size: 2.14vw">目標與任務</div>
          <div class="date"></div>
        </div>
        <div class="border"></div>
        <div class="content-img-div">
          <div class="content-text-div">
            <p>南部育成中心以發展農業生技產業、推動生物經濟產業發展為主，協助個人或企業利用前瞻技術開發糧食作物、經濟花卉、經濟作物、生物性製劑、中草藥產品、檢測試劑等關鍵技術及衍生性商品開發。</p>
            <p>為使本國學研業者能運用本院體學、新興農業生物技術平臺等研究、技術成果及農業生技相關設施，以加速我國種苗、生物製劑等研發，並與臺南學研單位合作共同進行技術開發及培育產業人才，以銜接學界之技術及人才，縮短個人或企業開發之期程，加速新技術、新商品、新產業之育成，帶動臺南生技產業之永續發展。</p>
          </div>
          <div class="img-div" style="justify-content: flex-start">
            <img src="../assets/target_task/image.png" style="height: 72.8%;">
          </div>
        </div>

      </div>
    </div>


    <div class="footer"></div>

    <menu-bar></menu-bar>
  </div>
</template>

<script>
import "@/assets/css/news_view/news-view.css"
import MenuBar from "@/components/MenuBar";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";
import axios from "axios";
import {ref} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";

export default {
  name: "NewsView",
  components:{
    MenuBar,
    ToolbarComponent,
  },
  setup(){
    window.scrollTo(0,0);

    const titleRef = ref("");
    const subtitleRef = ref("");
    const dateRef = ref("");
    const textRef = ref("");
    const imgRef = ref("");

    function routerBtn(path){
      router.push(path);
    }

    loadNews();

    function loadNews(){

      const route = useRoute()

      axios.post(window.phpPath+'php/news.php?func=query-one',{
        id:route.params.id
      })
          .then( (response) => {
            //console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);

            let title = json[0].title;
            let subtitle = json[0].subtitle;
            let date = json[0].date;
            let text = json[0].text;
            let img_name = (json[0].img_name == "") ? "" : window.phpPath + "php/upload/" + json[0].img_name;

            titleRef.value = title;
            subtitleRef.value = subtitle;
            dateRef.value = date;
            textRef.value = text;
            imgRef.value = img_name;

          })
          .catch( (error) => console.log(error))

    }

    return{
      routerBtn,
      titleRef,
      subtitleRef,
      dateRef,
      textRef,
      imgRef,
    }
  }
}
</script>

<style scoped>


</style>
