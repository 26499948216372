<template>
  <div class="relative logo-page logo-fade-in">
<!--    <div class="relative w-full"><img class="w-full" src="../assets/16x9.png"></div>-->
    <div class="logo-text-wrap">
      <img class="logo-en-fade-out" style="width: 58.7%;" src="../assets/home/logo/logo_text_en.png">
      <img class="logo-ch-fade-in" style="width: 35.8%;" src="../assets/home/logo/logo_text_ch.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoPage",

}
</script>

<style scoped>

/*Logo*/
.logo-page{
  width: 100%;
  height: 100vh;
  background: #00ACA0;
}

.logo-page .logo-text-wrap{
  position: absolute;
  height: 100%;
  width: 100%;
  top:0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-page .logo-text-wrap img{
  position: absolute;
}

.logo-fade-in {
  -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
  animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

.logo-ch-fade-in{
  -webkit-animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
  animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
}

.logo-en-fade-out{
  -webkit-animation: fade-out 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
  animation: fade-out 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
