<template>
  <div class="memory-page">
    <img class="bg" src="../assets/memorabilia/bg.png">
    <div class="logo-title-div" @click="routerBtn('/')"><img src="../assets/logo-title.png"></div>
    <div class="director-wrap">
      <div class="director-div">
        <div @click="routerBtn('/')">關於育成中心</div>
        <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
        <div>大事記</div>
      </div>
    </div>
    <div class="arrow-wrap">
      <img class="arrow" src="../assets/home/main/arrow.png" @click="leftArrowBtn">
      <img class="arrow" src="../assets/home/main/arrow.png" @click="rightArrowBtn">
    </div>

    <div class="slider-wrap">
      <div class="slider-div" :style="sliderX+fade">
        <div v-for="content in newsContent" :key="content" class="content-child-div" :class="curIndex==content[4]? 'head' : '' " :style="content[4]==0?'margin-left:0;':''">
          <div class="date">{{ content[2] }}</div>
          <div class="content-text">{{ content[1] }}</div>
          <div class="content-img">
            <img :src="content[3]" v-if="content[3] != '' ">
          </div>
        </div>
      </div>


    </div>

    <menu-bar></menu-bar>
  </div>

</template>

<script>
import router from "@/router";
import MenuBar from "@/components/MenuBar";
import {reactive, ref} from "vue";
import axios from "axios";

export default {
  name: "MemorabiliaView",
  components:{
    MenuBar,
  },
  setup() {
    window.scrollTo(0, 0);

    const curIndex = ref(0);
    const newsContent = reactive([]);
    const sliderX = ref('');
    const fade = ref('');


    function routerBtn(path){
      router.push(path);
    }

    load();

    function load(){

      axios.get(window.phpPath+'php/memorabilia.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i< json.length; i++){

              let id = json[i].id;
              let title = json[i].title;
              let date = json[i].date.split('-')[0]+"-"+json[i].date.split('-')[1];
              let img_name = (json[i].img_name == "") ? "" : window.phpPath + "php/upload/" + json[i].img_name;

              // let left = 'left:'+ (33+17.5*(i-1)).toString() + "%";
              // switch (i){
              //   case 0: left=''; break;
              //   case 1: left = 'left:33%'; break;
              // }

              let array = [id, title, date, img_name, i];
              newsContent.push(array);
            }


          })
          .catch( (error) => console.log(error))

    }


    function rightArrowBtn(){
      curIndex.value += 1;

      curIndex.value = Math.min(newsContent.length-1, curIndex.value);

      sliderX.value = ' -webkit-transform: translateX(' + -21.3 * curIndex.value + '%);' +
                      'transform:translateX(' + -21.3 * curIndex.value + '%);';

      fade.value = '-webkit-animation: fade-in-i ease-in 1s;';

      setTimeout(()=>{
        fade.value = '';
      }, 1000);
    }

    function leftArrowBtn(){
      curIndex.value -= 1;
      curIndex.value = Math.max(0, curIndex.value);

      sliderX.value = ' -webkit-transform: translateX(' + -21.3 * curIndex.value + '%);' +
          'transform:translateX(' + -21.3 * curIndex.value + '%);';

      fade.value = '-webkit-animation: fade-in-i ease-in 1s;';

      setTimeout(()=>{
        fade.value = '';
      }, 1000);
    }

    return{
      routerBtn,
      curIndex,
      newsContent,
      sliderX,
      rightArrowBtn,
      leftArrowBtn,
      fade,
    }
  }
}
</script>

<style scoped src="../assets/css/memorabilia.css">


</style>
