import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ManageView from "@/views/ManageView";
import NewsView from "@/views/NewsView";
import NewsListView from "@/views/NewsListView";
import NewsEventListView from "@/views/NewsEventListView";
import MemorabiliaView from "@/views/MemorabiliaView";
import TargetTaskView from "@/views/TargetTaskView";
import NurturingEnvironmentView from "@/views/NurturingEnvironmentView";
import ReasearchFacilitiesView from "@/views/ResearchFacilitiesView";
import ApplicatonView from "@/views/ApplicatonView";
import LogoView from "@/views/LogoView";
import TrafficView from "@/views/TrafficView";
import RedirectView from "@/views/RedirectView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/logo',
    name: 'logo',
    component: LogoView,
  },
  {
    path: '/news/:id?',
    name: 'news',
    component: NewsView,
  },
  {
    path: '/news-list',
    name: 'news-list',
    component: NewsListView,
  },
  {
    path: '/news-event-list',
    name: 'news-event-list',
    component: NewsEventListView,
  },
  {
    path: '/memorabilia',
    name: 'memorabilia',
    component: MemorabiliaView,
  },
  {
    path: '/target-task',
    name: 'target-task',
    component: TargetTaskView,
  },
  {
    path: '/nurturing-environment',
    name: 'nurturing-environment',
    component: NurturingEnvironmentView,
  },
  {
    path: '/research-facilities',
    name: 'research-facilities',
    component: ReasearchFacilitiesView,
  },
  {
    path: '/application/:page?',
    name: 'application',
    component: ApplicatonView,
  },
  {
    path: '/traffic',
    name: 'traffic',
    component: TrafficView,
  },
  {
    path: '/redirect/:next?',
    name: 'redirect',
    component: RedirectView,
  },
  {
    path: '/manage',
    name: 'manage',
    component: ManageView,
    meta:{
      title : "中央研究院南部育成中心後臺管理"
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to) =>{
//   if(to.meta.title){
//     document.title = to.meta.title;
//   }else{
//     document.title = "中央研究院南部育成中心";
//   }
// })

export default router
