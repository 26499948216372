<template>
  <div class="relative application-view">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div v-if="state == 'process'">
      <div class="content-wrap">
        <div class="content-div">
          <div class="director-div">
            <div @click="routerBtn('/')">關於育成中心</div>
            <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
            <div  style="color: #3B3938">申請流程</div>
          </div>
          <div class="title" >Application Process</div>
          <div class="sub-div">
            <div class="subtitle" style="font-size: 2.14vw">申請流程</div>
          </div>
          <div class="border"></div>
          <div class="content-img-div" >
            <div class="content-text-div"  style="text-align: justify">
              <p style="font-weight: bold; font-size: 1.4vw;">申請資格</p>
              <p>在中華民國依法完成設立或商業登記之公司或具有自主研發能力及新創技術之個人；均可提出申請。</p>
              <p style="font-weight: bold; font-size: 1.4vw;">租用對象：</p>
              <p>1. 國內外廠商。 2. 具備自主研發能力及新創技術之個人。 3. 院內外之學研單位。</p>
              <p style="font-weight: bold; font-size: 1.4vw;">審查程序及標準</p>
              <img style="width: 100%; margin:2% 0 2% 0" src="../assets/application/img.png">
              <p>1. 申請人應檢附進駐申請書、營運計畫書及相關文件。</p>
              <p>2. 申請案採隨到隨審，申請結果於申請案正式成案日起3個月內以書面通知．</p>
              <p>3. 申請人遞交申請進駐文件後，由本中心按申請案發展項目組成專案委員會進行審查。未獲通過者，申請人得於收受申請結果日起1個月內提出申覆1次；經申覆後仍未獲通過者，申請人於收受申覆結果日起半年內不得提出類似之申請案。</p>
              <p>4. 申請案經專案委員會審查通過後，將審查結果送交諮詢委員會審查，通過後通知申請人簽署合約進駐育成培育空間；未獲通過者，申請人得於收受申請結果日起1個月內提出申覆1次；經申覆後仍未獲通過者，申請人於收受申覆結果日起半年內不得提出類似之申請案。</p>
              <p>5.  申請人應於接獲核准進駐通知後之3個月內與本中心簽訂書面契約，並依契約約定辦理遷入作業。若申請人無法及時辦理遷入須提供有效理由(如資金募集等)並取得本中心同意延展進駐時程。</p>

              <p style="font-weight: bold; font-size: 1.4vw;">申請所需文件 (請至<span class="download" @click="anchorBtn()">資料專區</span><img class='download-img' src="../assets/home/data/download2.png">下載)</p>
              <p>1. 進駐申請書1份。</p>
              <p>2. 公司、商業登記證或營利事業登記證影本1份。</p>
              <p>3. 營運計畫書1份（公司/自然人），含紙本及電子檔各1份．</p>
              <p>4. 最近三年之財務報告與稅捐機關納稅證明文件1份。（公司成立不滿三年得依實際成立年數提供，新公司及自然人則免）</p>
              <p>5. 同意審查聲明書1份。</p>
              <p>6. 本中心得視情況要求申請人提供補充說明文件</p>
              <p style="text-indent:-1.5vw; margin-left: 1.3vw; text-align: justify">＊ 申請相關文件之電子檔請寄至e-mail：<a style="color: #00ACA0" href = "mailto: asicst@gate.sinica.edu.tw" >asicst@gate.sinica.edu.tw</a>；紙本請郵寄至地址：(115201)台北市南港區研究院路
                2段128號農科大樓(農生中心)A233室，中央研究院南部育成中心收．</p>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div v-if="state == 'method'">
      <div class="content-wrap">
        <div class="content-div">
          <div class="director-div">
            <div @click="routerBtn('/')">關於育成中心</div>
            <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
            <div  style="color: #3B3938">進駐收費</div>
          </div>
          <div class="title" >Price</div>
          <div class="sub-div">
            <div class="subtitle" style="font-size: 2.14vw">進駐收費</div>
          </div>
          <div class="border"></div>
          <div class="content-img-div">
            <div class="content-text-div">
              <div v-html="textRef" style="flex-grow : 1;"></div>
<!--              <p>進駐費用(金額未含稅價)</p>-->
<!--              <img style="margin-top: 3%; width: 100%" src="../assets/application/table.png">-->
              <div v-if="img_display">
                <img :src="imgRef">
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div v-if="state == 'pre'">
      <div class="content-wrap">
        <div class="content-div">
          <div class="director-div">
            <div @click="routerBtn('/')">關於育成中心</div>
            <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
            <div  style="color: #3B3938">前期諮詢</div>
          </div>
          <div class="title" >Preliminary Consultation</div>
          <div class="sub-div">
            <div class="subtitle">前期諮詢</div>
          </div>
          <div class="border"></div>

          <div class="content-text-div">
            <div class="contact-text">聯繫我們:asicst@gate.sinica.edu.tw 電話:02-27872137</div>
            <div>Hi,讓我認識您，我將提供您需要的幫助。</div>


            <div class="info-div" >
              <div class="title-div"><img src="../assets/application/info-title.png"></div>
              <div class="col-1">
                <div>公司名稱 : </div>
                <input type="text" maxlength="40" v-model="company" id="company">
              </div>
              <div class="col-2">
                <div>
                  <div>聯絡人姓名 : </div>
                  <input type="text" maxlength="20" v-model="name" id="name">
                </div>
                <div>
                  <div>聯絡人職稱 : </div>
                  <input type="text" maxlength="20" v-model="job" id="job">
                </div>
              </div>
              <div class="col-2">
                <div>
                  <div>聯絡人手機電話 : </div>
                  <input type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" maxlength="15" v-model="phone" id="phone">
                </div>
                <div>
                  <div>聯絡人E-mail : </div>
                  <input type="text" v-model="email" id="email">
                </div>
              </div>
            </div>

            <div class="tech-div">
              <div class="title-div"><img src="../assets/application/tech.png"></div>
              <div class="mul-img"><img src="../assets/application/mul-select.png"></div>
              <div class="row">
                <div class="col-1">
                  <label class="container">
                    <div>基因轉殖</div>
                    <input type="checkbox" name="tech" class="tech" value="基因轉殖"  >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技保健食品</div>
                    <input type="checkbox" name="tech" class="tech" value="生技保健食品"  >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技環保</div>
                    <input type="checkbox" name="tech" class="tech" value="生技環保" >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生物材料</div>
                    <input type="checkbox" name="tech" class="tech" value="生物材料" >
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="border"></div>
                <div class="col-1">
                  <label class="container">
                    <div>天然藥材</div>
                    <input type="checkbox" name="tech" class="tech" value="天然藥材"  >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>基因改良食品</div>
                    <input type="checkbox" name="tech" class="tech" value="基因改良食品"  >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>土地復育</div>
                    <input type="checkbox" name="tech" class="tech" value="土地復育" >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技製程</div>
                    <input type="checkbox" name="tech" class="tech" value="生技製程" >
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="border"></div>
                <div class="col-1">
                  <label class="container">
                    <div>生技種苗花卉</div>
                    <input type="checkbox" name="tech" class="tech" value="生技種苗花卉"  >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技飼養與添加劑</div>
                    <input type="checkbox" name="tech" class="tech" value="生技飼養與添加劑"  >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>能源生技</div>
                    <input type="checkbox" name="tech" class="tech" value="能源生技" >
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>其他 : </div>
                    <input type="checkbox" name="tech" class="tech" id="other-checkbox" :value="other" >
                    <span class="checkmark"></span>
                    <input type="text" v-model="other" id="otherInput">
                  </label>
                </div>
              </div>
            </div>

            <div class="other-div">
              <div class="title-div" style="width: 38.1%; left: 30.9%"><img src="../assets/application/experience.png"></div>
              <textarea id="experience" class="text-input" v-model="experience" maxlength="200">

              </textarea>
            </div>

            <div class="other-div">
              <div class="title-div"><img src="../assets/application/other.png"></div>
              <textarea class="text-input" v-model="request" maxlength="200">

              </textarea>
            </div>


            <div class="send-div" @click="sendBtn" >送出</div>
          </div>



        </div>
      </div>
    </div>

    <div v-if="state == 'confirm'">
      <div class="content-wrap">
        <div class="content-div">
          <div class="director-div">
            <div @click="routerBtn('/')">關於育成中心</div>
            <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
            <div  style="color: #3B3938">前期諮詢</div>
          </div>
          <div class="title" >Preliminary Consultation</div>
          <div class="sub-div">
            <div class="subtitle">前期諮詢</div>
          </div>

          <img style="position:absolute; width:9.5%; bottom: 5%; right: 7%" src="../assets/application/asicst.png">

          <div class="confirm-text-div">
            <div class="info-div">
              <div class="title-div">基本資料 : </div>
              <div class="row">
                <div class="col-1">
                  <div class="label">公司名稱 : <label>{{ company }}</label></div>
                </div>
                <div class="col-1">
                  <div class="label">聯絡人姓名 : <label>{{ name }}</label></div>
                  <div class="label">聯絡人職稱 : <label>{{ job }}</label></div>
                </div>
                <div class="col-1">
                  <div class="label">聯絡人手機電話 :<label>{{ phone }}</label> </div>
                  <div class="label">聯絡人E-mail : <label>{{ email }}</label></div>
                </div>
              </div>


            </div>
            <div class="tech-div">
              <div class="title-div">營業項目/發展技術 : </div>
              <div class="row">
                <div class="col-1">
                  <label class="container">
                    <div>基因轉殖</div>
                    <input type="checkbox" class="tech-confirm" id="tech-1" value="基因轉殖"  disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技保健食品</div>
                    <input type="checkbox" class="tech-confirm" id="tech-2" value="生技保健食品"  disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技環保</div>
                    <input type="checkbox" class="tech-confirm" id="tech-3" value="生技環保" disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生物材料</div>
                    <input type="checkbox" class="tech-confirm" id="tech-4" value="生物材料" disabled>
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-1">
                  <label class="container">
                    <div>天然藥材</div>
                    <input type="checkbox" class="tech-confirm" id="tech-5" value="天然藥材"  disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>基因改良食品</div>
                    <input type="checkbox" class="tech-confirm" id="tech-6" value="基因改良食品"  disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>土地復育</div>
                    <input type="checkbox" class="tech-confirm" id="tech-7" value="土地復育" disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技製程</div>
                    <input type="checkbox" class="tech-confirm" id="tech-8" value="生技製程" disabled>
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-1">
                  <label class="container">
                    <div>生技種苗花卉</div>
                    <input type="checkbox" class="tech-confirm" id="tech-9" value="生技種苗花卉"  disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>生技飼養與添加劑</div>
                    <input type="checkbox" class="tech-confirm" id="tech-10" value="生技飼養與添加劑"  disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>能源生技</div>
                    <input type="checkbox" class="tech-confirm" id="tech-11" value="能源生技" disabled>
                    <span class="checkmark"></span>
                  </label>
                  <label class="container">
                    <div>其他 : </div>
                    <input type="checkbox" class="tech-confirm" id="tech-12" :value="other" disabled>
                    <span class="checkmark"></span>
                    <label>{{ other }}</label>
                  </label>
                </div>
              </div>
            </div>

            <div class="other-div">
              <div class="title-div">簡述進駐團隊經歷、專長、背景 : </div>
              <div class="other-text">
                {{ experience }}
              </div>
            </div>

            <div class="other-div">
              <div class="title-div">其他需求 : </div>
              <div class="other-text">
                {{ request }}
              </div>
            </div>

            <div class="return-div" @click="state = 'pre' "><img src="../assets/application/return.png"></div>

            <div class="confirm-div" @click="confirmBtn">確定</div>
          </div>

        </div>
      </div>
    </div>

    <div class="footer"></div>

    <menu-bar></menu-bar>
  </div>

  <div v-if="uploading">
    <loading-component></loading-component>
  </div>
</template>

<script>
import MenuBar from "@/components/MenuBar";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";
import axios from "axios";
import {ref, nextTick} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";
import loadingComponent from "@/components/manage/LoadingComponent";

export default {
  name: "NewsView",
  components:{
    MenuBar,
    ToolbarComponent,
    loadingComponent
  },
  mounted() {
    init();

    function init(){
      let techConfirm = document.querySelectorAll('.tech');
      for (let i=0; i < techConfirm.length; i++){
        techConfirm[i].checked = false;
      }
    }
  },
  setup(){
    window.scrollTo(0,0);

    const state = ref("");
    const other = ref('');
    const company = ref('');
    const name = ref('');
    const job = ref('');
    const phone = ref('');
    const email = ref('');
    const request = ref('');
    const experience = ref('');
    const otherInput = ref(null);
    const uploading = ref(false);

    const route = useRoute();
    state.value = route.params.page;

    let tech = "";

    const textRef = ref("<p></p>");
    const imgRef = ref("");
    const img_display = ref(false);


    loadCharge();

    function routerBtn(path){
      router.push(path);
    }

    async function sendBtn(){
      if(company.value == ""){
        alert("請填寫公司名稱");
        document.getElementById('company').focus();
        return;
      }
      if(name.value == ""){
        alert("請填寫聯絡人姓名");
        document.getElementById('name').focus();
        return;
      }
      if(job.value == ""){
        alert("請填寫聯絡人職稱");
        document.getElementById('job').focus();
        return;
      }
      if(phone.value == ""){
        alert("請填寫聯絡人手機電話");
        document.getElementById('phone').focus();
        return;
      }
      if(email.value == ""){
        document.getElementById('email').focus();
        alert("請填E-mail");
        return;
      }

      if(experience.value == ""){
        document.getElementById('experience').focus();
        alert("請填團隊經歷、專長、背景");
        return;
      }

      tech = "";
      var checkedBoxes = document.querySelectorAll('input[name=tech]:checked');
      var otherCheckbox = document.getElementById("other-checkbox").checked;
      for(let i=0; i<checkedBoxes.length; i++){
        //console.log(checkedBoxes[i].value)
        tech += checkedBoxes[i].value+",";
      }

      if(checkedBoxes.length <= 0){
        alert("請選擇營業項目/發展技術");
        document.getElementById('other-checkbox').focus();
        return;
      }else if(otherCheckbox && other.value == ''){
        alert("請填寫其他營業項目/發展技術 其他欄");
        document.getElementById('otherInput').focus();
        return;
      }

      state.value = "confirm";

      await nextTick();

      let techConfirm = document.querySelectorAll('.tech-confirm');
      for (let i=0; i < techConfirm.length; i++){
        techConfirm[i].checked = false;
      }
      for (let i=0; i < techConfirm.length; i++){
        for (let j=0; j < checkedBoxes.length; j++){
          if(techConfirm[i].value == checkedBoxes[j].value){
            techConfirm[i].checked = true;
          }
        }
      }
    }

    function confirmBtn(){

      uploading.value = true;

      axios.post(window.phpPath+'php/application.php?func=insert',{
        company:company.value,
        name:name.value,
        job:job.value,
        phone:phone.value,
        email:email.value,
        tech:tech,
        experience:experience.value,
        other:request.value
      })
        .then( (response) => {
          //console.log(response)
          sendMail();
        })
        .catch( (error) => {
          console.log(error)
          alert(error);
          uploading.value = false;
        })



    }

    function anchorBtn(){
      window.anchor = 'data-page';
      routerBtn('/');
    }

    function loadCharge(){
      axios.get(window.phpPath+'php/charge.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);


            textRef.value = json[0].text;
            img_display.value = (json[0].img_display==1);
            imgRef.value = (json[0].img_name == "") ? "" : window.phpPath + "php/upload/"+json[0].img_name;

          })
          .catch( (error) => console.log(error));
    }

    function sendMail(){

      let content = "<p>公司名稱:"+ company.value +"</p>" +
          "<p>聯絡人姓名:"+ name.value +"</p>" +
          "<p>聯絡人職稱:"+ job.value +"</p>" +
          "<p>聯絡人手機電話:"+ phone.value +"</p>" +
          "<p>聯絡人E-mail:"+ email.value +"</p>" +
          "<p>營業項目/發展技術:"+ tech +"</p>" +
          "<p>簡述進駐團隊經歷、專長、背景:"+ experience.value +"</p>" +
          "<p>其他需求:"+ request.value +"</p>";

      axios.post(window.phpPath+'php/send_mail.php',{
        content:content,
        recipient:email.value
      }).then((res)=>{
        console.log(res)
            uploading.value = false;
            alert("送出成功");
            routerBtn('/');
      }).catch((e)=>{
        console.log(e)
        alert(e);
        uploading.value = false;
      })
    }

    return{
      routerBtn,
      state,
      sendBtn,
      confirmBtn,
      other,
      company,
      name,
      job,
      phone,
      email,
      request,
      experience,
      otherInput,
      anchorBtn,
      textRef,
      imgRef,
      img_display,
      uploading
    }
  }
}
</script>

<style scoped src="@/assets/css/application.css">


</style>
