<template>
  <div class="manage-page" id="manage-page" >

    <div style="width: 100%; display: flex; justify-content: center">
      <div class="edit-page" id="edit-page">
        <div class="title" >聯絡資訊</div>
        <div class="flex-wrap">
          <div class="text" v-if="showText">內文
<!--            <textarea rows="5" class="input-box" id="text-input" type="text" v-model="text"></textarea>-->
            <Tinymce class="input-box"  v-model:value="text"  ref="textHtml"/></div>
          <div class="btn-wrap" style="justify-content: center">
            <div class="send-btn" @click="updateBtn">修改</div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div v-if="uploading">
    <loading-component></loading-component>
  </div>


</template>

<script>
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import LoadingComponent from "@/components/manage/LoadingComponent";
import Tinymce from "@/components/Tinymce.vue";
//import tinymce from "tinymce";

export default {
  name: "ContactManage",
  props:{
    titleProp:String,
  },
  components:{
    LoadingComponent,
    Tinymce
  },
  setup(props){

    const  text = ref("");
    const showText = ref(false);
    const uploading = ref(false);
    const textHtml = ref();

    loadNews()

    function loadNews(){


      axios.get(window.phpPath+'php/contact.php?func=query')
          .then( (response) => {
            console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);
            text.value = json[0].text;
            showText.value = true;
          })
          .catch( (error) => console.log(error))

    }


    async function updateBtn(){
      uploading.value = true;

      let text = textHtml.value.content;

      axios.post(window.phpPath + 'php/contact.php?func=update', {
        text: text,
      })
          .then((response) => {
            //console.log(response)
            uploading.value = false;
            alert("修改成功")
          })
          .catch((error) => console.log(error))
    }



    return{
      text,
      uploading,
      textHtml,
      showText,
      updateBtn
    }
  },
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.add-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  margin-top: -1%;
  cursor: pointer;
}

.add-btn:hover{
  background: #008980;
}


.flex-wrap{
  width: 80%!important;
}

.btn-wrap{
  width: 60%!important;
}
</style>
