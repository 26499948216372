<template>
  <div class="menu-bar" @click="menuBtn">
    <div v-if="menuPanelStyle== 'menu-panel-close' "><img src="../assets/menu/menu.png"></div>
  </div>
  <div v-if="menuPanelStyle== 'menu-panel-open' " class="cancel-panel" @click="menuBtn"></div>

  <div class="menu-panel" :class="menuPanelStyle">

    <div class="flex items-center">
      <div class="menu-title"><img src="../assets/menu/menu-title.png"></div>
<!--      <div class="contact-btn">CONTACT</div>-->
      <a  class="contact-btn" href = "mailto: asicst@gate.sinica.edu.tw">CONTACT</a>
      <div class="cross" @click="menuBtn"><img src="../assets/cross.png"></div>
    </div>

    <div class="list">
      <div class="row">
        <div style="width: 19.7%"><img src="../assets/menu/menu-1.png"></div><div class="link-btn" @click="routeBtn('/memorabilia')">大事記</div><div class="link-btn" @click="routeBtn('/nurturing-environment')">培育環境</div><div class="link-btn" @click="routeBtn('/target-task')">目標與任務</div><div class="link-btn" @click="routeBtn('/research-facilities')">研發設施</div>
      </div>
      <div class="border"></div>
      <div class="row">
        <div style="width: 16.8%"><img src="../assets/menu/menu-2.png"></div><div class="link-btn" @click="routeBtn('/news-list')">最新消息</div><div class="link-btn" @click="routeBtn('/news-event-list')">活動資訊</div>
      </div>
      <div class="border"></div>
      <div class="row">
        <div style="width: 14.5%"><img src="../assets/menu/menu-3.png"></div><div class="link-btn" @click="redirectBtn('pre')">前期諮詢</div><div class="link-btn" @click="redirectBtn('method')">進駐收費</div><div class="link-btn" @click="redirectBtn('process')">申請流程</div>
      </div>
      <div class="border"></div>
      <div class="row">
        <div style="width: 14%"><img src="../assets/menu/menu-4.png"></div><div class="link-btn" @click="anchorBtn(0)">進駐申請</div><div class="link-btn" @click="anchorBtn(1)">跨領域大樓申請</div><div class="link-btn" @click="anchorBtn(2)">核心設施申請</div>
      </div>
      <div class="border"></div>
      <div class="row">
        <div style="width: 22.8%"><img src="../assets/menu/menu-5.png"></div><div class="link-btn" @click="openUrl('https://assc.sinica.edu.tw/ASSC/login.php?r=2273754140')">會議室</div><div class="link-btn" @click="openUrl('https://assc.sinica.edu.tw/ASSC/login.php?r=2273754140')">核心溫室</div><div class="link-btn" @click="openUrl('https://assc.sinica.edu.tw/ASSC/login.php?r=2273754140')">質譜儀</div>
      </div>

    </div>


    <img class="menu-logo" src="../assets/menu/menu-logo.png">
  </div>

</template>

<script>
import {nextTick, ref} from "vue";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";

export default {
  name: "MenuBar",
  setup(){

    const menuPanelStyle = ref('menu-panel-close');
    const route = useRoute();

    let open = false;

    function menuBtn(){
      open = !open;
      if(open){
        menuPanelStyle.value = "menu-panel-open";
      }else{
        menuPanelStyle.value = "menu-panel-close";
      }
    }

    function routeBtn(path){
      //router.push('/redirect'+path);
      router.push(path);
    }

    function redirectBtn(path){
      router.push('/redirect/'+path);
    }

    async function anchorBtn(page){

      window.dataPage = page.toString();
      window.anchor = 'data-page';
      router.push('/redirect/home');
    }

    function openUrl(url){
      window.open(url, '_blank');
    }




    return{
      menuBtn,
      menuPanelStyle,
      routeBtn,
      anchorBtn,
      redirectBtn,
      openUrl
    }
  }
}
</script>

<style scoped>
.menu-bar{
  position: fixed;
  width: 5.2%;
  height: 100vh;
  right: 0;
  top: 0;
  background: #00ACA0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}

.menu-bar > div{
  width: 50.5%;
}

.cancel-panel{
  position: fixed;
  width: 100%;
  height: 100vh;
  background: transparent;
  top:0;
  left: 0;
}

.menu-panel{
  position: fixed;
  width: 58.4%;
  height: 100vh;
  top: 0;

  z-index: 10;
  background: radial-gradient(131.87% 78.93% at 10.96% 4.15%, rgba(203, 231, 227, 0) 0.8%, rgba(173, 219, 212, 0.1252) 10.91%, rgba(127, 201, 193, 0.3134) 26.11%, rgba(81, 188, 178, 0.4987) 41.07%, rgba(9, 179, 168, 0.6779) 55.53%, rgba(0, 174, 162, 0.8482) 69.28%, #00ACA0 81.54%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  padding: 6.5% 0 0 10% ;
  opacity: 0;
  transition: right ease-out 0.5s, opacity ease-out 0.5s;
}

.menu-panel-open{
  opacity: 0.95;
  right: 5.2%;
}

.menu-panel-close{
  right: -60%;
}

.menu-panel .menu-logo{
  position: absolute;
  right: 0%;
  bottom: 0%;
  width: 58.6%;
}

.menu-panel .menu-title{
  position: relative;
  width: 47.8%;
}

.menu-panel .contact-btn{
  background: #FFFFFF;
  width: 21.8%;
  height: 2.6vw;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  font-weight: bold;
  color: #00ACA0;
  border-radius: 1.5vw;
  margin-left: 7%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.502));
}

.menu-panel .contact-btn:hover{
  background: #00ACA0;
  color: #FFFFFF;
}

.menu-panel .cross{
  width: 4.3%;
  margin-left: 13%;
  margin-top: -10%;
}

.menu-panel .list{
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 7%;
}

.menu-panel .list .row{
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.2vw;
  font-weight: bold;
  color: white;
  filter: drop-shadow(0px 0px 10px rgba(0, 159, 143, 0.502));
}

.menu-panel .list .row .link-btn{
  margin-top: -1.5%;
  margin-left: 4.1%;
  cursor: pointer;
  border:none;
  border-bottom: 2px solid transparent;
  transition: border linear 0.2s;
}

.menu-panel .list .row .link-btn:hover{
  border-bottom: 2px solid #FFF100;
}

.menu-panel .list .border{
  width: 77%;
  border: 1px solid white;
  margin-top: 2%;
  margin-bottom: 5%;
}

</style>
