<template>
  <div class="relative research-facilities-page">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div class="content-wrap">
      <div class="content-div">

        <div class="loop-image-div" id="loop-image-div-1">
          <div class="arrow-div">
            <div class="arrow-wrap">
              <img class="arrow" src="../assets/home/main/arrow.png" @click="leftArrowBtn">
              <img class="arrow" src="../assets/home/main/arrow.png" @click="rightArrowBtn">
            </div>

          </div>
          <div class="dot-div">
            <div>
              <div class="dot select" id="dot-1-0" @click="dotBtn(0)"></div>
              <div class="dot" id="dot-1-1" @click="dotBtn(1)"></div>
              <div class="dot" id="dot-1-2" @click="dotBtn(2)"></div>
              <div class="dot" id="dot-1-3" @click="dotBtn(3)"></div>
              <div class="dot" id="dot-1-4" @click="dotBtn(4)"></div>
            </div>
          </div>
        </div>

        <div class="top-content">
          <div class="director-div">
            <div @click="routerBtn('/')">關於育成中心</div>
            <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
            <div style="color: #3B3938">研發設施</div>
          </div>
          <div class="title" ><p>Core</p><p style="margin-top: -5%">Facilities</p></div>
          <div class="subtitle">植物核心溫室 (付費設施)</div>
          <div class="border"></div>
          <div class="content" >包含玻璃溫室非空調區、一般空調區、病理空調區、步入型人工照明生長室；精密溫室不同大小之生長箱設備，亦有符合標準之基因轉殖植物隔離區設施，歡迎洽詢。</div>
        </div>


        <div class="right-loop">
          <div class="loop-image-div" id="loop-image-div-2">
            <div class="arrow-div">
              <div class="arrow-wrap" >
                <img class="arrow" src="../assets/home/main/arrow.png" @click="leftArrowBtn2">
                <img class="arrow" src="../assets/home/main/arrow.png" @click="rightArrowBtn2">
              </div>

            </div>
            <div class="dot-div" >
              <div>
                <div class="dot select" id="dot-2-0" @click="dotBtn2(0)"></div>
                <div class="dot " id="dot-2-1" @click="dotBtn2(1)"></div>
                <div class="dot " id="dot-2-2" @click="dotBtn2(2)"></div>
                <div class="dot " id="dot-2-3" @click="dotBtn2(3)"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="down-content">
          <div class="title" >質譜儀室 (付費設施) </div>
          <div class="border"></div>
          <div class="content" style="text-align: justify">開放LC triple quadrupole Sciex 6500+、Orbitrap Fusion Lumos、GCMS-TQ8050 NX 、Autoflex maX MALDI-TOF/TOF  4台儀器，提供客製化委託操作服務及技術諮詢，歡迎洽詢。</div>
        </div>

        <div class="white-div">
          <div>公用儀器</div>
          <div></div>
          <div>包括核酸影像系統、聚合酶連鎖反應儀、分光光度計、即時定量核酸偵測系統、落地型高速離心機、冷凍高速離心機、旋轉震盪培養箱、高溫高壓滅菌鍋、純水機、製冰機、4℃、-20 ℃與-80 ℃冰箱、酸鹼度計、電子天秤、加熱攪拌器等。</div>
        </div>
      </div>
    </div>


    <div class="footer"></div>

    <menu-bar></menu-bar>
  </div>
</template>

<script>
import "@/assets/css/research-facilities.css"

import MenuBar from "@/components/MenuBar";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";
import axios from "axios";
import {ref} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";

export default {
  name: "NurturingEnvironmentView",
  components:{
    MenuBar,
    ToolbarComponent,
  },
  setup(){
    window.scrollTo(0,0);

    let index = 0;
    let dotCount = 5;
    let index2 = 0;
    let dotCount2 = 4;
    let timeout = 5000;
    let timeoutId1 = null;
    let timeoutId2 = null;


    let animId = null;
    let animId2 = null;

    clearInterval();

    // animId = setInterval(animation,timeout);
    // animId2 = setInterval(animation2,timeout);

    function animation(){
      index++;
      if(index > (dotCount-1)){
        index=0;
      }

      changeDot(index, 1);
    }

    function animation2(){
      index2++;
      if(index2 > (dotCount2-1)){
        index2=0;
      }

      changeDot(index2, 2);
    }

    function changeDot(num, id) {

      let timeoutId = (id==1) ? timeoutId1 : timeoutId2;

      clearTimeout(timeoutId)

      let path = (id==1) ? "a-"+(index+1) : "b-"+(index2+1);
      let count =  (id==1) ? dotCount: dotCount2;

      document.getElementById('loop-image-div-'+id).classList.add("loop-image-fade-out");
      document.getElementById('loop-image-div-'+id).classList.remove("loop-image-fade-in");

      for (var i = 0; i < count; i++)
        document.getElementById("dot-" + id + "-" + i).classList.remove("select");

      document.getElementById("dot-" + id + "-" + num).classList.add("select");

      /*測試*/
      document.getElementById('loop-image-div-'+id).style.backgroundImage='url('+ getImgUrl(path) +')';

      timeoutId = setTimeout(function (){
        document.getElementById('loop-image-div-'+id).style.backgroundImage='url('+ getImgUrl(path) +')';
        document.getElementById('loop-image-div-'+id).classList.add("loop-image-fade-in");
        document.getElementById('loop-image-div-'+id).classList.remove("loop-image-fade-out");
      }, 150);
    }

    function clearInterval(){
      //刪除所有SetInterval
      var interval_id = window.setInterval(()=>{}, 99999);
      for (var i = 0; i < interval_id; i++)
        window.clearInterval(i);

      window.clearInterval(animId);
      animId = setInterval(animation,timeout);
      window.clearInterval(animId2);
      animId2 = setInterval(animation2,timeout);
    }

    function rightArrowBtn(){
      index++;
      if(index > (dotCount-1)){
        index = 0;
      }
      clearInterval();
      changeDot(index, 1);
    }

    function leftArrowBtn(){
      index--;

      if(index < 0){
        index = dotCount-1;
      }
      clearInterval();
      changeDot(index, 1);
    }

    function dotBtn(dotIndex){
      index = dotIndex;

      clearInterval();
      changeDot(index, 1);
    }

    function rightArrowBtn2(){
      index2++;
      if(index2 > (dotCount2-1)){
        index2 = 0;
      }
      clearInterval();
      changeDot(index2, 2);
    }

    function leftArrowBtn2(){
      index2--;

      if(index2 < 0){
        index2 = dotCount2-1;
      }
      clearInterval();
      changeDot(index2, 2);
    }

    function dotBtn2(dotIndex){
      index2 = dotIndex;
      clearInterval();

      changeDot(index2, 2);
    }

    function getImgUrl(name) {
      var images = require.context('../assets/research-facilities/', false, /\.jpg$/)
      return images('./' + name + ".jpg")
    }

    function routerBtn(path){
      router.push(path);
    }


    return{
      leftArrowBtn,
      rightArrowBtn,
      dotBtn,
      leftArrowBtn2,
      rightArrowBtn2,
      dotBtn2,
      routerBtn,
    }
  }
}
</script>

<style scoped>


</style>
