<template>
  <div class="manage-page" id="manage-page" >

    <div  v-if="state == 'list'" style="width: 100%;">
      <div class="title">大事記</div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        <div class="create-new-btn" @click="createBtn">建立新事記</div>
        <table id="table">
          <tbody id="manage-tbody">
          <tr class="table-title">
            <td>編號</td>
            <td>日期</td>
            <td>標題</td>
            <td>編輯</td>
            <td>刪除</td>
          </tr>

          <tr v-for="(content, index) in tableContent" :key="content">
            <!--        v-for="data in content" :key="data"-->
            <td>{{ index+1 }}</td>
            <td>{{ content[1] }}</td>
            <td>{{ content[2] }}</td>
            <td :id="'edit-'+content[0]" class="edit-btn" @click="editBtn(index, content[0])">編輯</td>
            <td :id="'delete-'+content[0]" class="edit-btn" @click="deleteBtn(content[0])">刪除</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div v-if="state == 'edit' || state == 'create'" style="width: 100%; display: flex; justify-content: center">
      <div class="edit-page" id="edit-page">
        <div class="title">編輯</div>
        <div class="flex-wrap">
          <div class="text">日期<input class="input-box" id="date-input" type="date" v-model="date"></div>
          <div class="text">標題<input class="input-box" id="title-input" type="text" v-model="title" maxlength="100"></div>
          <div class="input-file-div">圖片
            <div><input class="input-file-box" type="file" @change="handleUploadFile" :value="uploadImgRef"></div>
            <div class="preview-div"><img :src="previewImgRef" v-if="previewImgRef != null"></div>
          </div>
          <div class="btn-wrap">
            <div class="back-btn" @click="backBtn">返回</div>
            <div class="send-btn" @click="updateBtn()" v-if="state == 'edit' ">修改</div>
            <div class="send-btn" @click="updateBtn()" v-if="state == 'create' ">建立</div>

          </div>
        </div>
      </div>
    </div>

  </div>

  <div v-if="uploading">
    <loading-component></loading-component>
  </div>

</template>

<script>
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import LoadingComponent from "@/components/manage/LoadingComponent";

export default {
  name: "NewsManage",
  props:{
    titleProp:String,
  },
  components:{
    LoadingComponent
  },
  setup(props){

    const tableContent = reactive([[]]);
    const  title = ref("");
    const  date = ref("");
    const  state = ref("list");
    const  uploadImgRef = ref(null);
    const  previewImgRef = ref(null);
    const uploading = ref(false);


    let curUpdateId = 0;
    let uploadFile = null;

    loadNews();

    function loadNews(){

      axios.get(window.phpPath+'php/memorabilia.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);

            tableContent.splice(0, tableContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i < json.length; i++) {
              let id = json[i].id;
              let title = json[i].title;
              let date = json[i].date;
              let img_name = json[i].img_name;

              let array = [id, date, title, img_name];
              tableContent.push(array);
            }


          })
          .catch( (error) => console.log(error))

    }

    function editBtn(index, id){
      uploadImgRef.value = null;
      uploadFile = null;

      state.value = "edit";

      date.value = tableContent[index][1];
      title.value = tableContent[index][2];
      previewImgRef.value = (tableContent[index][3] == "") ? "" : window.phpPath + "php/upload/"+tableContent[index][3];

      curUpdateId = id;
    }

    function backBtn(){
      state.value = "list";

      loadNews();
    }

    function handleUploadFile(e){
      uploadFile = e.target.files[0];
      previewImgRef.value = URL.createObjectURL(e.target.files[0]);
    }

   function updateBtn(){

     if(date.value == ''){
       alert("請填寫日期");
       return;
     }

      uploading.value = true;

      if(uploadFile != null){
        let formData = new FormData();
        formData.append("file", uploadFile);
        axios.post(window.phpPath+'php/upload.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response)=>{
          //console.log(response.request.responseText);
          if(response.request.responseText == 'type'){
            alert("上傳檔案類型錯誤");
            uploading.value = false;
            return;
          }else{
            updateNews(response.request.responseText);
          }
        }).catch((e)=>{
          console.log(e)
          alert(e.response);
        });
      }else{
        updateNews("");
      }
    }

    function updateNews(filename){

      if(state.value == "edit") {
        axios.post(window.phpPath + 'php/memorabilia.php?func=update', {
          id: curUpdateId,
          title: title.value,
          date: date.value,
          img_name: filename
        })
            .then((response) => {
              //console.log(response.request.responseText)
              uploading.value = false;
              backBtn();
            })
            .catch((error) => {
              console.log(error)
              alert(error.response);
            })
      }

      if(state.value == "create"){
        axios.post(window.phpPath+'php/memorabilia.php?func=insert',{
          title:title.value,
          date:date.value,
          img_name:filename
        })
            .then( (response) => {
              //console.log(response)
              uploading.value = false;
              backBtn();
            })
            .catch( (error) => {
              console.log(error)
              alert(error.response);
            })
      }
    }

    function createBtn(){
      title.value = "";
      date.value = "";
      uploadImgRef.value = null;
      uploadFile = null;
      previewImgRef.value = null;
      state.value = "create";
    }

    function deleteBtn(id){
      if (window.confirm("確定要刪除嗎?")) {
        axios.post(window.phpPath+'php/memorabilia.php?func=delete',{
          id:id,
        })
            .then( (response) => {
              //console.log(response)
              backBtn();
            })
            .catch( (error) => console.log(error))
      }
    }


    return{
      editBtn,
      backBtn,
      updateBtn,
      tableContent,
      title,
      date,
      state,
      handleUploadFile,
      uploadImgRef,
      previewImgRef,
      deleteBtn,
      createBtn,
      uploading
    }
  },
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
