<template>
<div class="manage-page">
  <div  v-if="state == 'list'" style="width: 100%;">
    <!--  資料專區  -->
    <div class="title">{{titleProp}}</div>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
    <div class="create-new-btn" @click="createBtn">建立{{titleProp}}</div>
<!--      <div class="create-new-btn" @click="updateData">建立新資料</div>-->
<!--      <input class="input-file-box" type="file" @change="handleUploadFile">-->
      <table id="table">
        <tbody id="manage-tbody">
        <tr class="table-title">
          <td>排序</td>
          <td>資料名稱</td>
          <td>Tag標籤</td>
          <td>編輯</td>
          <td>刪除</td>
        </tr>

        <tr v-for="(content, index) in dataContent" :key="content">
          <td>{{ content[6] }}</td>
          <td>{{ content[2] }}</td>
          <td>{{ content[4] }}</td>
          <td :id="'edit-'+content[0]" class="edit-btn" @click="editBtn(content[0], index)">編輯</td>
          <td class="edit-btn" @click="deleteBtn(content[0])">刪除</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div v-if="state == 'edit' || state == 'create'" style="width: 100%; display: flex; justify-content: center">
    <div class="edit-page" id="edit-page">
      <div class="title" v-if="state=='edit'">編輯</div>
      <div class="title" v-if="state=='create'">建立</div>
      <div class="flex-wrap">

        <div class="text">資料名稱<input class="input-box" id="title-input" type="text" v-model="dataName" maxlength="100"></div>
        <div class="text">檔案排序<input class="input-box"  type="number" v-model="dataOrder" ></div>
        <div class="input-file-div" style="margin-top: 10%">
          <span>上傳檔案</span>
          <input class="input-file-box justify-between" type="file" @change="handleUploadFile">
          <span @click="downloadBtn()" class="download-btn" v-if="state=='edit'" style="margin-top: 5%">瀏覽目前檔案</span>
        </div>


        <div class="text" style="margin-top: 10%">
            <span v-if="createNewTag==false">
              Tag標籤
               <select style="margin-left: 3%; border: 1px solid #3B3938" v-model="tagSelectRef">
              <option v-for="content in tagContent" :key="content" :value="content[0]">{{ content[1] }}</option>
            </select>
            </span>
          <span class="tag-input-div" style="margin-top: 3%" v-if="createNewTag==true">
              輸入新Tag標籤
              <input type="text" maxlength="10" v-model="newTagName" style="border: 1px solid black;border-radius: 5px; padding-left: 5px;padding-right: 5px; width: 25%;">
            </span>
          <span  class="add-btn" @click="createNewTag=!createNewTag">{{ createNewTag?'-返回標籤':'+新增標籤' }}</span>

        </div>
        <div class="btn-wrap">
          <div class="back-btn" @click="backBtn">返回</div>
          <div class="send-btn" @click="updateBtn()" v-if="state == 'edit' ">修改</div>
          <div class="send-btn" @click="updateBtn()" v-if="state == 'create' ">建立</div>

        </div>
      </div>
    </div>
  </div>

</div>

<div v-if="uploading">
  <loading-component></loading-component>
</div>


</template>

<script>
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import LoadingComponent from "@/components/manage/LoadingComponent";
import {Base64} from "js-base64";

export default {
  name: "DataManage",
  props:{
    titleProp :String,
    sortId : Number,
  },
  components:{
    LoadingComponent
  },
  setup(props) {
    const dataContent = reactive([[]]);
    const state = ref("list");
    const dataName = ref('');
    const createNewTag = ref(false);
    const newTagName = ref('');
    const tagContent = reactive([]);
    const tagSelectRef = ref(null);
    const uploading = ref(false);
    const dataOrder = ref();
    let curUpdateId = 0;
    let curUpdateIndex = 0;
    let uploadFile = null;
    let tagId = -1;
    let orderLength = 0;

    loadData();

    function loadData() {

      axios.post(window.phpPath + 'php/data.php?func=query-data-by-sort',{
        sortId:props.sortId
      })
          .then((response) => {
            //console.log(response.request.responseText);

            dataContent.splice(0, dataContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i = 0; i < json.length; i++) {
              let id = json[i].data_id;
              let data_name = json[i].data_name;
              let file_name = json[i].file_name;
              let sort_id = json[i].sort_id;
              let tag_name = "#" + json[i].tag_name;
              let tag_id = json[i].tag_id;
              let data_order = json[i].data_order;

              let sort_name = "";
              switch (sort_id) {
                case '0':
                  sort_name = "進駐申請";
                  break;
                case '1':
                  sort_name = "跨領域研究大樓申請";
                  break;
                case '2':
                  sort_name = "核心設施申請";
                  break;
              }

              orderLength = json.length+1;

              let array = [id, sort_name, data_name, file_name, tag_name, tag_id, data_order];
              dataContent.push(array);
            }


          })
          .catch((error) => console.log(error))

    }

    function loadTag() {
      axios.post(window.phpPath + 'php/data.php?func=query-tag-by-sort',{
        sortId:props.sortId
      })
          .then((response) => {
            //console.log(response.request.responseText);

            tagContent.splice(0, tagContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i = 0; i < json.length; i++) {
              let id = json[i].tag_id;
              let tag_name = json[i].tag_name;

              let array = [id, tag_name];
              tagContent.push(array);
            }
          })
          .catch((error) => console.log(error))
    }

    function updateBtn() {
      uploading.value = true;


      if (uploadFile != null) {
        let formData = new FormData();
        formData.append("file", uploadFile);
        formData.append('filename', uploadFile.name)

         axios.post(window.phpPath + 'php/upload.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          //console.log(response);

          if(response.request.responseText == 'type'){
            alert("上傳檔案類型錯誤");
            uploading.value = false;
            return;
          }else{
            updateData(response.request.responseText);
          }
        }).catch((e) => {
          console.log(e)
           if(e.response.status == 413){
              alert('上傳檔案超過大小');
           }
           else{
             alert('error : ' + e.response.status + ' 上傳檔案錯誤');
           }
           uploading.value = false;
        });
      } else {
        if(state.value == "create"){
          alert("請選擇上傳檔案");
          uploading.value = false;
        }else{
          updateData('');
        }
      }
    }

    async function updateData(filename) {
      tagId = tagSelectRef.value;

      if(createNewTag.value){
        await  axios.post(window.phpPath + 'php/data.php?func=insert-tag', {
          sort_id: props.sortId,
          tag_name: newTagName.value,
        })
            .then((response) => {
              console.log(response.request.responseText)
              tagId = response.request.responseText;

            })
            .catch((error) => console.log(error))
      }

      insertData(filename);
    }

    function insertData(filename){
      if(state.value == "edit") {
        axios.post(window.phpPath + 'php/data.php?func=update',{
          id: curUpdateId,
          data_name:dataName.value,
          file_name: filename,
          tag_id: tagId,
          data_order:dataOrder.value
        })
          .then((response) => {
            //console.log(response.request.responseText)
            uploading.value = false;
            backBtn();
        }).catch((error) => console.log(error))
      }

      if(state.value == "create"){
        axios.post(window.phpPath+'php/data.php?func=insert',{
          sort_id:props.sortId,
          data_name:dataName.value,
          file_name: filename,
          tag_id: tagId,
          data_order:dataOrder.value
        })
        .then( (response) => {
          //console.log(response)
          uploading.value = false;
          backBtn();
        })
        .catch( (error) => console.log(error))
      }
    }

    function backBtn(){
      state.value = "list";

      loadData();
    }

    function handleUploadFile(e){
      //console.log(e.target.files[0].name)
      uploadFile = e.target.files[0];
    }

    function deleteBtn(id){
      if (window.confirm("確定要刪除嗎?")) {
        axios.post(window.phpPath+'php/data.php?func=delete',{
          data_id:id
        })
            .then( (response) => {
              //console.log(response)
              loadData();
            })
            .catch( (error) => console.log(error))
      }

    }

    function editBtn(id, index){
      loadTag();
      state.value = "edit";
      createNewTag.value = false;


      dataName.value = dataContent[index][2];
      tagSelectRef.value = dataContent[index][5];
      dataOrder.value = dataContent[index][6];
      curUpdateIndex = index;
      curUpdateId = id;
    }

    function createBtn(){
      loadTag();


      dataName.value = '';
      tagSelectRef.value = 1;
      uploadFile = null;
      state.value = "create";
      createNewTag.value = false;
      dataOrder.value = orderLength;
    }

    function downloadBtn(){
      let file = dataContent[curUpdateIndex][3];
      let filename = Base64.decode(file);

      var link = document.createElement('a');
      link.setAttribute("target", "_blank");
      link.href = window.phpPath+'php/upload/'+filename;
      link.dispatchEvent(new MouseEvent('click'));
    }

    return{
      dataContent,
      updateData,
      handleUploadFile,
      deleteBtn,
      state,
      dataName,
      createNewTag,
      newTagName,
      editBtn,
      tagSelectRef,
      tagContent,
      downloadBtn,
      updateBtn,
      backBtn,
      createBtn,
      uploading,
      dataOrder
    }
  }
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.add-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  margin-top: -1%;
  cursor: pointer;
}

.add-btn:hover{
  background: #008980;
}

.download-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  cursor: pointer;
}

.download-btn:hover{
  background: #008980;
}
</style>
