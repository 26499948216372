<template>
  <div class="relative news-view-page">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div class="content-wrap">
      <div class="content-div">
        <div class="director-div">
          <div @click="routerBtn('/news-event-list')">消息與活動</div>
          <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
          <div @click="routerBtn('/news-list')">最新消息</div>
          <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
          <div class="title-div">{{ titleRef }}</div>
        </div>
        <div class="title" >{{ titleRef }}</div>
        <div class="sub-div">
          <div class="subtitle">{{ subtitleRef }}</div>
          <div class="date">{{ dateRef }}</div>
        </div>
        <div class="border"></div>
        <div class="content-img-div">
          <div class="content-text-div" >
<!--            {{ textRef }}-->
            <div v-html="textRef" id="content-html" style="flex-grow : 1;"></div>
          </div>
          <div class="img-div" v-if="img_display">
            <img :src="imgRef" @click="openImg(imgRef)">
          </div>
        </div>

      </div>
    </div>

    <div class="footer"></div>

    <menu-bar></menu-bar>

    <div class="img-viewer" v-if="imgViewerShow" @click="close">
      <img :src="imgViewerRef">
      <div class="close" ></div>
    </div>

  </div>
</template>

<script>
import "@/assets/css/news_view/news-view.css"
import MenuBar from "@/components/MenuBar";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";
import axios from "axios";
import {ref} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";

export default {
  name: "NewsView",
  components:{
    MenuBar,
    ToolbarComponent,
  },
  setup(){
    window.scrollTo(0,0);

    const titleRef = ref("");
    const subtitleRef = ref("");
    const dateRef = ref("");
    const textRef = ref("<p></p>");
    const imgRef = ref("");
    const img_display = ref(false);
    const imgViewerRef = ref(window.phpPath+"/php/upload/1670914367.png");
    const imgViewerShow = ref(false);

    function routerBtn(path){
      router.push(path);
    }

    loadNews();

    async function loadNews(){

      const route = useRoute()

      await axios.post(window.phpPath+'php/news.php?func=query-one',{
        id:route.params.id
      })
          .then( (response) => {
            //console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);

            let title = json[0].title;
            let subtitle = json[0].subtitle;
            let date = json[0].date;
            let text = json[0].text;
            let img_name = (json[0].img_name == "") ? "" : window.phpPath + "php/upload/" + json[0].img_name;


            titleRef.value = title;
            subtitleRef.value = subtitle;
            dateRef.value = date;
            textRef.value = text;
            imgRef.value = img_name;
            img_display.value = (json[0].img_display==1);





          })
          .catch( (error) => console.log(error))

        var cont = document.getElementById("content-html");
        var all_img = cont.getElementsByTagName('img');

        for (var i = 0; i < all_img.length; i++) {
          //console.log(all_img[i]);
          all_img[i].classList.add('cursor-pointer');
          all_img[i].addEventListener('click',(e)=>{
            openImg(e.target.currentSrc)
          },false);
        }
    }

    function close(){
      imgViewerShow.value = false;
    }

    function openImg(img){
      imgViewerRef.value = img;
      imgViewerShow.value = true;
    }

    return{
      routerBtn,
      titleRef,
      subtitleRef,
      dateRef,
      textRef,
      imgRef,
      img_display,
      imgViewerRef,
      imgViewerShow,
      close,
      openImg
    }
  }
}
</script>

<style scoped>
.close {
  position: absolute;
  right: 0%;
  top: 4%;
  width: 10%;
  background-color: white;
  opacity: 1;
}
.close:hover {
  opacity: 0.5;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: white;
  cursor: pointer;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.img-viewer{
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.img-viewer img{
  height: 100%;
  width: auto;
  cursor: default;
}

.content-img-div img{
  cursor: pointer;
}


</style>
