<template>
  <div class="relative wrap home">

    <div v-if="state == 'logo'"  :class="logoStyle">
      <LogoPage></LogoPage>
    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import LogoPage from "@/components/LogoPage";
import MainPage from "@/components/MainPage";
import NewsPage from "@/components/NewsPage";
import AboutPage from "@/components/AboutPage";
import ApplicationPage from "@/components/ApplicationPage";
import DataAreaPage from "@/components/DataAreaPage";
import ContactPage from "@/components/ContactPage";
import MenuBar from "@/components/MenuBar";
import router from "@/router";
import {ref} from "vue";

export default {
  name: 'HomeView',
  components: {
    NewsPage,
    LogoPage,
    MainPage,
    AboutPage,
    ApplicationPage,
    DataAreaPage,
    ContactPage,
    MenuBar,
  },
  setup(){
    const state = ref('logo');
    const logoStyle = ref(null);

    setTimeout(()=>{
      logoStyle.value = 'fade-out';
    }, 6000);

    setTimeout(()=>{
      sessionStorage.setItem('logo', '1');
      router.push('/');
    }, 6500);

    return{
      state,
      logoStyle
    }
  }

}
</script>

<style>
@-webkit-keyframes fade-in-i {
  0% {
    opacity: 0.3;
  }
  100%{
    opacity: 1;
  }
}
@keyframes fade-in-i {
  0% {
    opacity: 0.3;
  }
  100%{
    opacity: 1;
  }
}
</style>

<style scoped>

/*news*/

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.home .fade-in{
  -webkit-animation: fade-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fade-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.home .fade-out{
  -webkit-animation: fade-out 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fade-out 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.footer{
  position: relative;
  width: 100%;
  height: 60px;
  background: #00ACA0;
}
</style>
