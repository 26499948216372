<template>
  <div class="toolbar-wrap">
    <div class="toolbar">
      <div class="logo-title-div" @click="routerBtn('/')"><img src="../assets/logo-title.png"></div>
      <div class="search-div relative">
        <input type="text" v-model="searchInput" @keyup.enter="searchBtn">
        <div class="search-send cursor-pointer" @click="searchBtn"><img src="../assets/home/main/search-btn.png" ></div>
      </div>
      <a href = "mailto: asicst@gate.sinica.edu.tw" class="contact-us-btn cursor-pointer">聯絡我們</a>
    </div>
  </div>

</template>

<script>
import router from "@/router";
import {ref} from "vue";

export default {
  name: "ToolbarComponent",
  setup(){

    const searchInput = ref();


    function routerBtn(path){
      router.push(path);
    }

    function searchBtn(){
      window.open("https://www.google.com/search?q=" + searchInput.value +" site:https://asicst.goten.com.tw/", "_blank")
    }

    return{
      routerBtn,
      searchBtn,
      searchInput
    }
  }
}
</script>

<style scoped>

.toolbar-wrap .toolbar{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
//margin-top: 2.5%;
}


.toolbar-wrap .logo-title-div{
  position: relative;
  width: 20%;
  margin-left: 2%;
  cursor: pointer;
}

.toolbar-wrap .search-div{
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 48.3%;
  width: 12.7%;
  height: 2.5vw;
}

.toolbar-wrap .search-div input[type="text"]{
  position: relative;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 5px rgba(0, 159, 143, 0.502));
  border-radius: 25px;
  outline: none;
  padding-left: 8%;
  padding-right: 23%;
  font-size: 1vw;
}

.toolbar-wrap .search-div .search-send{
  position: absolute;
  width: 16.5%;
  right: 2%;
}

.toolbar-wrap .search-div .search-send:hover {
  filter: grayscale(0.3);
}

.toolbar-wrap .contact-us-btn{
  width: 7.1%;
  height: 2.6vw;
  font-size: 1.2vw;
  font-weight: 400;
  filter: drop-shadow(0px 0px 5px rgba(0, 159, 143, 0.502));
  background: #009F8F;
  border-radius: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.9%;
}

.toolbar-wrap .contact-us-btn:hover{
  background: #007f72;
}
</style>
