<template>
  <div class="manage-page" id="manage-page" >

    <div style="width: 100%; display: flex; justify-content: center">
      <div class="edit-page" id="edit-page">
        <div class="title" >SEO設定</div>
        <div class="flex-wrap">
          <div class="text">Title<input class="input-box" id="title-input" type="text" v-model="title"></div>
          <div class="text">Keyword<input class="input-box" id="title-input" type="text" v-model="keywords"></div>
          <div class="text">Description<input class="input-box" id="title-input" type="text" v-model="description" ></div>
          <div class="btn-wrap" style="justify-content: center">
            <div class="send-btn" @click="update">修改</div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div v-if="uploading">
    <loading-component></loading-component>
  </div>


</template>

<script>
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import LoadingComponent from "@/components/manage/LoadingComponent";

export default {
  name: "SEOManage",
  props:{
    titleProp:String,
  },
  components:{
    LoadingComponent,
  },
  setup(){
    const title = ref('');
    const keywords = ref('');
    const description = ref('');
    const uploading = ref(false);

    load();

    function load(){
      axios.post(window.phpPath+'php/seo.php?func=query')
          .then((res)=>{

            let json = JSON.parse(res.request.responseText);

            title.value = json[0].title;
            keywords.value = json[0].keywords;
            description.value = json[0].description;
          })
          .catch((e)=>{
            console.log(e)
          })
    }

    function update(){
      uploading.value = true;
      axios.post(window.phpPath+'php/seo.php?func=update',{
        title:title.value,
        keywords:keywords.value,
        description:description.value
      })
          .then((res)=>{
            alert("修改成功");
            uploading.value = false;
          })
          .catch((e)=>{
            console.log(e)
            uploading.value = false;
          })
    }



    return{
      title,
      keywords,
      description,
      update,
      uploading
    }
  }
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.add-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  margin-top: -1%;
  cursor: pointer;
}

.add-btn:hover{
  background: #008980;
}


.flex-wrap{
  width: 80%!important;
}

.btn-wrap{
  width: 60%!important;
}
</style>
