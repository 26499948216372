<template>
<div class="manage-page">
  <div  v-if="state == 'list'" style="width: 100%; display: flex; justify-content: center; padding-top: 10%">
    <div style="width: 40%">
      <div class="title">消息活動標籤</div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        <table id="table">
          <tbody id="manage-tbody">
          <tr class="table-title">
            <td>編號</td>
            <td>標籤名稱</td>
            <td>刪除</td>
          </tr>

          <tr v-for="(content, index) in newsContent" :key="content">
            <td>{{ index+1 }}</td>
            <td>{{ content[1] }}</td>
            <td class="edit-btn" @click="deleteNewsBtn(content[0])">刪除</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div style="width: 40%">
      <div class="title">資料專區標籤</div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        <table id="table">
          <tbody id="manage-tbody">
          <tr class="table-title">
            <td>編號</td>
            <td>類別</td>
            <td>標籤名稱</td>
            <td>刪除</td>
          </tr>

          <tr v-for="(content, index) in dataContent" :key="content">
            <td>{{ index+1 }}</td>
            <td>{{ content[1] }}</td>
            <td>{{ content[2] }}</td>
            <td class="edit-btn" @click="deleteDataBtn(content[0])">刪除</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>

</div>
</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";

export default {
  name: "TagManage",
  setup() {
    const newsContent = reactive([[]]);
    const dataContent = reactive([[]]);
    const state = ref("list");

    loadNewsTag();
    loadDataTag();

    function loadNewsTag() {
      axios.get(window.phpPath + 'php/news.php?func=tag-query')
          .then((response) => {
            //console.log(response.request.responseText);

            newsContent.splice(0, newsContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i = 0; i < json.length; i++) {
              let id = json[i].tag_id;
              let tag_name = json[i].tag_name;

              let array = [id, tag_name];
              newsContent.push(array);
            }
          })
          .catch((error) => console.log(error))
    }

    function loadDataTag() {
      axios.get(window.phpPath + 'php/data.php?func=query-tag')
          .then((response) => {
            //console.log(response.request.responseText);

            dataContent.splice(0, dataContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i = 0; i < json.length; i++) {
              let id = json[i].tag_id;
              let sort_id = json[i].sort_id;
              let tag_name = json[i].tag_name;

              let sort_name = "";
              switch (sort_id){
                case '0': sort_name='進駐申請'; break;
                case '1': sort_name='跨領域研究大樓申請';break;
                case '2': sort_name='核心設施申請';break;
              }

              let array = [id, sort_name, tag_name];
              dataContent.push(array);
            }
          })
          .catch((error) => console.log(error))
    }

    function deleteNewsBtn(id){
      if (window.confirm("確定要刪除嗎?")) {
        axios.post(window.phpPath+'php/news.php?func=delete-tag',{
          tagId:id
        })
            .then( (response) => {
              loadNewsTag();
            })
            .catch( (error) => console.log(error))
      }

    }

    function deleteDataBtn(id){
      if (window.confirm("確定要刪除嗎?")) {
        axios.post(window.phpPath+'php/data.php?func=delete-tag',{
          tag_id:id
        })
            .then( (response) => {
              loadDataTag();
            })
            .catch( (error) => console.log(error))
      }
    }


    return{
      newsContent,
      dataContent,
      deleteNewsBtn,
      deleteDataBtn,
      state,
    }
  }
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.add-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  margin-top: -1%;
  cursor: pointer;
}

.add-btn:hover{
  background: #008980;
}

.download-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  cursor: pointer;
}

.download-btn:hover{
  background: #008980;
}
</style>
