<template>
  <div class="main-page">
    <div class="navbar">
      <div class="page-btn-div" @click="scrollToView('about-page', 1)"><img src="../assets/home/main/about.png"><div class="border"></div></div>
      <div class="page-btn-div" @click="scrollToView('news-page', 1.25)"><img src="../assets/home/main/news.png"><div class="border"></div></div>
      <div class="page-btn-div" @click="scrollToView('application-page', 1)"><img src="../assets/home/main/application.png"><div class="border"></div></div>
      <div class="page-btn-div" @click="openUrl('https://assc.sinica.edu.tw/ASSC/login.php?r=2273754140')"><img src="../assets/home/main/space.png"><div class="border"></div></div>
      <div class="page-btn-div" @click="scrollToView('data-page', 1)"><img src="../assets/home/main/data.png"><div class="border"></div></div>

      <div class="search-div relative">
        <input type="text" v-model="searchInput" @keyup.enter="searchBtn">
        <div class="search-send cursor-pointer"><img src="../assets/home/main/search-btn.png" @click="searchBtn"></div>
      </div>

      <a href = "mailto: asicst@gate.sinica.edu.tw" class="contact-us-btn cursor-pointer">聯絡我們</a>
<!--      <div class="en-btn cursor-pointer">EN+</div>-->
    </div>
    <img class="dot-bg" src="../assets/gray-dot.png">
    <div class="main-logo" id="main-logo"><img src="../assets/home/main/main-logo.png"></div>
    <div class="content-wrap">
      <div class="loop-image-div" id="loop-image-div">
        <div class="arrow-div">
          <div class="arrow-wrap">
            <img class="arrow" src="../assets/home/main/arrow.png" @click="leftArrowBtn">
            <img class="arrow" src="../assets/home/main/arrow.png" @click="rightArrowBtn">
          </div>

        </div>
        <div class="dot-div">
          <div>
            <div v-for="n in dotCount" class="dot"  :id="'dot-'+(n-1)"  @click="dotBtn(n-1)" ></div>
<!--            <div class="dot" id="dot-1" @click="dotBtn(1)"></div>-->
<!--            <div class="dot" id="dot-2" @click="dotBtn(2)"></div>-->
          </div>
        </div>
      </div>
      <div class="content">
        <div><img class="main-logo-sub" id="main-logo-sub" src="../assets/home/main/main-logo-sub.png" ></div>

        <div id="new-loader">
<!--                  <news-div title="最新消息" subtitle="中央研究院南部育成中心" date="2022-07-07" newHidden=""></news-div>-->


          <div class="news-div" v-for="content in newsContent" :key="content" @click="routerBtn('/news/'+content[0])">
            <div class="news-wrap">
              <div class="date"><div class="new-div"><img :class="content[4]" src="@/assets/home/main/new.png"></div>{{ content[3] }}</div>
              <div>
                <div class="title">{{content[1]}}</div>
                <div class="subtitle">{{ content[2] }}</div>
              </div>
              <div class="arrow" ><img src="@/assets/home/main/arrow2.png"></div>
            </div>

            <div class="border"></div>
          </div>

        </div>

        <div class="more" @click="routerBtn('/news-list')">
          <div>MORE</div>
          <div><img src="../assets/home/main/arrow2.png"></div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import "@/assets/css/home/main.css"
import axios from "axios";
import {nextTick, reactive, ref} from "vue";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";

export default {
  name: "MainPage",
  components:{

  },
  data() {
    return {
      preload_img : ["../assets/main/輪播圖0", "../assets/main/輪播圖1", "../assets/main/輪播圖2"],
    }
  },
  mounted() {

    setTimeout(()=>{
      var observer = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('main-logo').classList.add('fade-anim');
          document.getElementById('main-logo-sub').classList.add('fade-anim');
          observer.disconnect();
        }
      }, { threshold: 0.1 });

      observer.observe(document.querySelector("#main-logo"));
    }, 10);



  },
  setup(){
    window.scrollTo(0,0);

    const newsContent = reactive([]);
    const imgArr = reactive([]);
    let index = 0;
    const dotCount = ref(0);
    let timeout = 5000;
    let timeoutId = null;
    const searchInput = ref();


    clearInterval();
    loadNews();
    loadMain();

    //setInterval(animation,timeout);


    SetAnchorView();

    async function SetAnchorView(){
      await nextTick();

      if(window.anchor != ''){
        scrollToView(window.anchor, 1);
        window.anchor='';
      }
    }

    async function loadMain(){
      axios.post(window.phpPath+'php/main.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);



            let json = JSON.parse(response.request.responseText);
            dotCount.value = json.length;

            for (var i=0; i< json.length; i++){
              imgArr.push(window.phpPath+'php/upload/' + json[i].img_name);
            }


            index = -1;

            setTimeout(animation,10);
            setInterval(animation,timeout);

          })
          .catch( (error) => console.log(error))
    }


    function animation(){
      index++;
      if(index > (dotCount.value-1)){
        index=0;
      }

      changeDot(index);
    }

    function changeDot(num) {
      clearTimeout(timeoutId)

      document.getElementById('loop-image-div').classList.add("loop-image-fade-out");
      document.getElementById('loop-image-div').classList.remove("loop-image-fade-in");

      for (var i = 0; i < dotCount.value; i++)
        document.getElementById("dot-" + i).classList.remove("select");

      document.getElementById("dot-" + num).classList.add("select");

      timeoutId = setTimeout(function (){
        //document.getElementById('loop-image-div').style.backgroundImage='url('+ getImgUrl('輪播圖'+index) +')';
        document.getElementById('loop-image-div').style.backgroundImage='url('+ imgArr[index] +')';
        document.getElementById('loop-image-div').classList.add("loop-image-fade-in");
        document.getElementById('loop-image-div').classList.remove("loop-image-fade-out");
      }, 150);
    }

    function clearInterval(){
      //刪除所有SetInterval
      var interval_id = window.setInterval(()=>{}, 99999);
      for (var i = 0; i < interval_id; i++)
        window.clearInterval(i);
    }

    function rightArrowBtn(){
      index++;
      if(index > (dotCount.value-1)){
        index = 0;
      }
      clearInterval();
      changeDot(index);


      setInterval(animation,5000);
    }

    function leftArrowBtn(){
      index--;

      if(index < 0){
        index = dotCount.value-1;
      }
      clearInterval();
      changeDot(index);


      setInterval(animation,timeout);
    }

    function dotBtn(dotIndex){
      index = dotIndex;
      clearInterval();

      changeDot(index);
      setInterval(animation,5000);
    }

    function getImgUrl(name) {
      var images = require.context('../assets/home/main/', false, /\.jpg$/)
      return images('./' + name + ".jpg")
    }

    function loadNews(){

      axios.get(window.phpPath+'php/news.php?func=query-news')
          .then( (response) => {
            //console.log(response.request.responseText);



            let json = JSON.parse(response.request.responseText);
            let count = (json.length >= 4) ? 4 : json.length;
            for (var i=0; i< count; i++){

                let id = json[i].main_id;
                let title = json[i].title;
                let subtitle = json[i].subtitle;
                let date = json[i].date;
                let newHidden = (json[i].new==0) ? "hidden":"";

                let array = [id, title, subtitle, date, newHidden];
                newsContent.push(array);
              }


          })
          .catch( (error) => console.log(error))

    }

    function routerBtn(path){
      router.push(path);
    }

    function scrollToView(id, mul){
      window.scrollTo({
        top: document.getElementById(id).offsetTop * mul,
        behavior: 'smooth'
      })
    }

    function openUrl(url){
      window.open(url, "_blank");
    }



    function searchBtn(){
      window.open("https://www.google.com/search?q=" + searchInput.value +" site:https://asicst.goten.com.tw/", "_blank")
    }

    return{
      leftArrowBtn,
      rightArrowBtn,
      dotBtn,
      newsContent,
      routerBtn,
      scrollToView,
      openUrl,
      dotCount,
      searchInput,
      searchBtn
    }
  }
}
</script>

<style scoped>

</style>
