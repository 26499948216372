<template>
  <div class="data-page" id="data-page">
    <div class="logo-div">
      <img src="../assets/home/data/data_logo.png">
    </div>
    <div class="out-frame">
        <div class="explain"><img src="../assets/home/data/explain.png"></div>
        <div class="page-wrap-div">
        <div class="page-div" @click="pageBtn(0)" :class="curPage == 0 ? 'selected':''">
          <div>進駐申請</div>
          <div>Application For Entry</div>
        </div>
        <div class="page-div" @click="pageBtn(1)" :class="curPage == 1 ? 'selected':''">
          <div>跨領域研究大樓申請</div>
          <div>Interdisciplinary Research Building Application</div>
        </div>
        <div class="page-div" @click="pageBtn(2)" :class="curPage == 2 ? 'selected':''">
          <div>核心設施申請</div>
          <div>Core Facilities Application</div>
        </div>
      </div>

        <div class="tag-wrap">
          <div class="tag-div" v-for="content in tagArr[curPage]" :key="content" @click="tagBtn(content[1])" :class="curTag==content[1] ? 'selected':''">{{ content[0] }}</div>
        </div>
        <div class="inter-frame">
            <div class="content-list" @scroll="scrollEvent($event)">
              <div class="flex-list">
                <div class="content-div" v-for="(content) in fileArr[curPage]" :key="content" @click="downloadBtn(content[2])" >
                  <div class="num-div">{{ content[0] }}</div>
                  <div class="title-div">{{ content[1] }}</div>
                  <div class="download-div"><img src="../assets/home/data/download.png"></div>
                </div>

              </div>

            </div>

            <img class="down-arrow" src="../assets/home/data/down_arrow.png" v-if="hasScrolledToBottom==false">
        </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home/data.css"
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router/dist/vue-router";
import {Base64} from "js-base64";

export default {
  name: "DataAreaPage",
  setup(){

    const tagArr = reactive([[]]);
    const fileArr = reactive([[]]);
    const curPage= ref(0);
    const curTag = ref(-1);
    const hasScrolledToBottom = ref(false);

    loadTag();
    loadData(-1);

    if(window.dataPage != ''){
      curPage.value = Number(window.dataPage);
      window.dataPage = '';
    }


    function loadTag(){
      axios.get(window.phpPath+'php/data.php?func=query-tag')
          .then( (response) => {
            //console.log(response.request.responseText);

            tagArr.splice(0, tagArr.length);
            for (var i=0; i<3; i++)
              tagArr.push([]);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i < json.length; i++) {
              let sort_id = json[i].sort_id;
              let tag_id = json[i].tag_id;
              let tag_name = "# " + json[i].tag_name;

              let array = [tag_name, tag_id];
              tagArr[sort_id].push(array);
            }


          })
          .catch( (error) => console.log(error))
    }

    function pageBtn(index){
      curPage.value = index;
      tagBtn(-1);
      setScroll();

    }

    function setScroll(){
      //是否顯示向下箭頭
      hasScrolledToBottom.value = !(fileArr[curPage.value].length > 5);
    }

    function loadData(tag){


      let api = (tag!=-1) ? "php/data.php?func=query-data-by-tag" : "php/data.php?func=query-data";
      axios.post(window.phpPath+api,{
        tagId:tag
      })
          .then( (response) => {
            //console.log(response.request.responseText);

            fileArr.splice(0, tagArr.length);
            for (var i=0; i<3; i++)
              fileArr.push([]);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i < json.length; i++) {
              let sort_id = json[i].sort_id;
              let num = (fileArr[sort_id].length+1).toString().padStart(2, '0');
              let data_name = json[i].data_name;
              let file_name = json[i].file_name;
              let tag_id = json[i].tag_id;

              let array = [num, data_name, file_name, sort_id, tag_id];
              fileArr[sort_id].push(array);
            }

            setScroll();

          })
          .catch( (error) => console.log(error))
    }

    function downloadBtn(file){
      let filename = Base64.decode(file);

      var link = document.createElement('a');
      link.setAttribute("target", "_blank");
      link.href = window.phpPath+'php/upload/'+filename;
      //link.download = 'file_new.docx';
      link.dispatchEvent(new MouseEvent('click'));
    }

    function tagBtn(tag_id){
      if(curTag.value == tag_id){
        curTag.value = -1;
        loadData(-1);
      }else{
        curTag.value = tag_id;
        loadData(tag_id);
      }
    }

    function scrollEvent(el){
      //console.log(el.srcElement.offsetHeight + el.srcElement.scrollTop + "   " + el.srcElement.scrollHeight)
      if((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight-10) {
        hasScrolledToBottom.value = true;
      }else{
        hasScrolledToBottom.value = false;
      }
    }

    return{
      tagArr,
      curPage,
      pageBtn,
      downloadBtn,
      fileArr,
      tagBtn,
      curTag,
      scrollEvent,
      hasScrolledToBottom
    }
  },
}
</script>

<style scoped>



</style>
