<template>
  <div class="manage-page" id="manage-page" >

    <div  v-if="state == 'list'" style="width: 100%;">
      <div class="title">{{ titleProp }}</div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        <div class="create-new-btn" @click="createBtn">建立{{titleProp}}</div>
        <table id="table">
          <tbody id="manage-tbody">
          <tr class="table-title">
            <td>編號</td>
            <td>標題</td>
            <td>副標題</td>
            <td>日期</td>
            <td v-if="titleProp=='最新消息'">顯示NEW</td>
            <td>編輯</td>
            <td>刪除</td>
          </tr>

          <tr v-for="(content, index) in tableContent" :key="content">
            <!--        v-for="data in content" :key="data"-->
            <td>{{ index+1 }}</td>
            <td>{{ content[1] }}</td>
            <td>{{ content[2] }}</td>
            <td>{{ content[3] }}</td>
            <td v-if="titleProp=='最新消息'">{{ content[4] }}</td>
            <td :id="'edit-'+content[0]" class="edit-btn" @click="editBtn(content[0])">編輯</td>
            <td :id="'delete-'+content[0]" class="edit-btn" @click="deleteBtn(content[0])">刪除</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div v-if="state == 'edit' || state == 'create'" style="width: 100%; display: flex; justify-content: center">
      <div class="edit-page" id="edit-page">
        <div class="title" v-if="state=='edit'">編輯</div>
        <div class="title" v-if="state=='create'">建立</div>
        <div class="flex-wrap">
          <div class="text">標題<input class="input-box" id="title-input" type="text" v-model="title" maxlength="30"></div>
          <div class="text">副標題<input class="input-box" id="subtitle-input" type="text" v-model="subtitle" maxlength="30"></div>
          <div class="text">日期<input class="input-box" id="date-input" type="date" v-model="date"></div>
          <div class="text">內文
<!--            <textarea rows="5" class="input-box" id="text-input" type="text" v-model="text"></textarea>-->
            <Tinymce class="input-box"  v-model:value="text" id="test" ref="textHtml"/></div>

          <div class="text" >是否在內文顯示圖片<input type="checkbox" id="new-input" v-model="img_display"></div>
          <div class="input-file-div">圖片
            <div><input class="input-file-box" type="file" @change="handleUploadFile" :value="uploadImgRef"></div>
            <div class="preview-div"><img :src="previewImgRef" v-if="previewImgRef != null"></div>
          </div>
          <div class="text" v-if="titleProp!='最新消息'">
            <span v-if="createNewTag==false">
              Tag標籤
               <select style="margin-left: 3%; border: 1px solid #3B3938" v-model="tagSelectRef">
              <option v-for="content in tagContent" :key="content" :value="content[0]">{{ content[1] }}</option>
            </select>
            </span>
            <span class="tag-input-div" style="margin-top: 3%" v-if="createNewTag==true">
              輸入新Tag標籤
              <input type="text" maxlength="10" v-model="newTagName" style="border: 1px solid black;border-radius: 5px; padding-left: 5px;padding-right: 5px; width: 25%;">
            </span>
            <span  class="add-btn" @click="createNewTag=!createNewTag">{{ createNewTag?'-返回標籤':'+新增標籤' }}</span>

          </div>
          <div class="text" v-if="titleProp=='最新消息'">是否顯示NEW標籤<input type="checkbox" id="new-input" v-model="new_display"></div>
          <div class="btn-wrap">
            <div class="back-btn" @click="backBtn">返回</div>
            <div class="send-btn" @click="updateBtn()" v-if="state == 'edit' ">修改</div>
            <div class="send-btn" @click="updateBtn()" v-if="state == 'create' ">建立</div>

          </div>
        </div>
      </div>
    </div>
  </div>



  <div v-if="uploading">
    <loading-component></loading-component>
  </div>


</template>

<script>
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import LoadingComponent from "@/components/manage/LoadingComponent";
import Tinymce from "@/components/Tinymce.vue";
//import tinymce from "tinymce";

export default {
  name: "NewsManage",
  props:{
    titleProp:String,
  },
  components:{
    LoadingComponent,
    Tinymce
  },
  setup(props){

    const tableContent = reactive([[]]);
    const  title = ref("");
    const  subtitle = ref("");
    const  date = ref("");
    const  text = ref("");
    const  new_display = ref(false);
    const  img_display = ref(false);
    const  state = ref("list");
    const  uploadImgRef = ref(null);
    const  previewImgRef = ref(null);

    const tagContent = reactive([]);
    const tagSelectRef = ref(null);
    const createNewTag = ref(false);
    const newTagName = ref('');
    const uploading = ref(false);

    let curUpdateId = 0;
    let uploadFile = null;
    let tagId = 0;

    const textHtml = ref();

    loadTag();
    loadNews();


    function loadTag(){
      axios.get(window.phpPath+'php/news.php?func=tag-query')
          .then( (response) => {
            //console.log(response.request.responseText);

            tagContent.splice(0, tagContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i < json.length; i++) {
              let id = json[i].tag_id;
              let tag_name = json[i].tag_name;

              let array = [id, tag_name];
              tagContent.push(array);
            }

            //loadNews();
          })
          .catch( (error) => console.log(error))
    }

    function loadNews(){

      let api = "";

      switch (props.titleProp){
        case '最新消息': api = 'php/news.php?func=query-by-tag'; break;
        case '消息與活動':api = 'php/news.php?func=query-no-news' ;break;
      }

      axios.post(window.phpPath+api,{
        tagId:1
      })
          .then( (response) => {
            //console.log(response.request.responseText);

            tableContent.splice(0, tableContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i < json.length; i++) {
              let id = json[i].main_id;
              let title = json[i].title;
              let subtitle = json[i].subtitle;
              let date = json[i].date;
              let display = (json[i].new == 0) ? "否" : "是";
              let text = json[i].text;
              let tag_id = json[i].tag_id;
              let img_name = json[i].img_name;
              let img_display = json[i].img_display;

              let array = [id, title, subtitle, date, display, text, tag_id, img_name, img_display];
              tableContent.push(array);
            }


          })
          .catch( (error) => console.log(error))

    }

    function editBtn(id){
      loadTag();
      uploadImgRef.value = null;
      uploadFile = null;

      state.value = "edit";
      createNewTag.value = false;

      //let id = event.currentTarget.id.replace('edit-', "");
      let index = 0;

      for (var i=0; i<tableContent.length; i++){
        if(id == tableContent[i][0]){
          index = i;
          break;
        }
      }
      title.value = tableContent[index][1];
      subtitle.value = tableContent[index][2];
      date.value = tableContent[index][3];
      new_display.value = (tableContent[index][4]=="否" ? false : true);
      text.value = tableContent[index][5];
      tagSelectRef.value = tableContent[index][6];
      previewImgRef.value = (tableContent[index][7] == "") ? "" : window.phpPath + "php/upload/"+tableContent[index][7];
      img_display.value = (tableContent[index][8]==1);

      curUpdateId = id;
    }

    function backBtn(){
      state.value = "list";

      loadNews();
    }

    function handleUploadFile(e){
      uploadFile = e.target.files[0];
      previewImgRef.value = URL.createObjectURL(e.target.files[0]);
    }

    async function updateBtn(){

      if(date.value == ''){
        alert("請填寫日期");
        return;
      }

      uploading.value = true;

      await nextTick();

      if(uploadFile != null){
        let formData = new FormData();
        formData.append("file", uploadFile);
        axios.post(window.phpPath+'php/upload.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response)=>{
          //console.log(response.request.responseText);
          if(response.request.responseText == 'type'){
            alert("上傳檔案類型錯誤");
            uploading.value = false;
            return;
          }else{
            updateNews(response.request.responseText);
          }
        }).catch((e)=>{
          console.log(e)
          alert(e.response);
        });
      }else{
        updateNews("");
      }
    }

    async function updateNews(filename){

      if(props.titleProp == '最新消息'){
        tagId = 1;
      }else{
        tagId = tagSelectRef.value;
      }

      if(createNewTag.value){
       await  axios.post(window.phpPath + 'php/news.php?func=insert-tag', {
          tag_name: newTagName.value,
        })
        .then((response) => {
          //console.log(response.request.responseText)
          tagId = response.request.responseText;
        })
        .catch((error) => {
          console.log(error)
          alert(error.response);
        })
      }

      insertData(filename);
    }

    function insertData(filename){

      let text = textHtml.value.content;

      if(state.value == "edit") {
        axios.post(window.phpPath + 'php/news.php?func=update', {
          id: curUpdateId,
          title: title.value,
          subtitle: subtitle.value,
          date: date.value,
          text: text,
          new: new_display.value,
          tag_id: tagId,
          img_name: filename,
          img_display: (img_display.value==false ? 0 : 1)
        })
            .then((response) => {
              //console.log(response)
              uploading.value = false;
              backBtn();
            })
            .catch((error) => {
              console.log(error)
              alert(error.response);
            })
      }

      if(state.value == "create"){
        axios.post(window.phpPath+'php/news.php?func=insert',{
          title:title.value,
          subtitle:subtitle.value,
          date:date.value,
          text:text,
          new:new_display.value,
          tag_id:tagId,
          img_name:filename,
          img_display: (img_display.value==false ? 0 : 1)
        })
            .then( (response) => {
              //console.log(response)
              uploading.value = false;
              backBtn();
            })
            .catch( (error) => {
              console.log(error)
              alert(error.response);
            })
      }
    }

    function createBtn(){
      loadTag();
      title.value = "";
      subtitle.value = "";
      date.value = "";
      new_display.value = false;
      text.value = "";
      tagSelectRef.value = 2;
      uploadImgRef.value = null;
      uploadFile = null;
      previewImgRef.value = null;
      state.value = "create";
      createNewTag.value = false;
    }

    function deleteBtn(id){
      if (window.confirm("確定要刪除嗎?")) {
        axios.post(window.phpPath+'php/news.php?func=delete',{
          id:id,
        })
            .then( (response) => {
              //console.log(response)
              backBtn();
            })
            .catch( (error) => console.log(error))
      }
    }


    return{
      editBtn,
      backBtn,
      updateBtn,
      tableContent,
      title,
      subtitle,
      text,
      date,
      new_display,
      state,
      tagContent,
      tagSelectRef,
      handleUploadFile,
      uploadImgRef,
      previewImgRef,
      deleteBtn,
      createBtn,
      createNewTag,
      newTagName,
      uploading,
      textHtml,
      img_display
    }
  },
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.add-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  margin-top: -1%;
  cursor: pointer;
}

.add-btn:hover{
  background: #008980;
}


.flex-wrap{
  width: 80%!important;
}

.btn-wrap{
  width: 60%!important;
}
</style>
