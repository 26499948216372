<template>
  <div class="contact-page">

    <div class="title-wrap">
      <div class="title-div">
        <img src="../assets/home/contact/title.png">
      </div>
    </div>


    <div class="loop-title-wrap">
      <div class="loop-title-div" id="loop-title-div"><img id="loop-title-img" src="../assets/home/contact/loop_title_Right.png"></div>
    </div>

    <div class="content-wrap">
      <div class="content-div" style="width: 40%; height: 19vw">
        <div class="content-img" style="display: flex; flex-grow: 1">
          <div v-html="textRef" style="flex-grow : 1;"></div>
<!--          <div>經營團隊</div>-->
<!--          <div><img src="../assets/home/contact/icon.png">創新轉譯農學研究計畫辦公室</div>-->
<!--          <div>Innovative Translational Agricultural Research Administrative Office</div>-->
<!--          <div>115201 臺北市南港區研究院路2段128號農科大樓A233室</div>-->
<!--          <div>TEL : 02-2787-2129 (代表號)</div>-->
<!--          <div>FAX : 02-2789-2477</div>-->
        </div>

<!--        <div>經營團隊</div>-->
<!--        <div style="width: 100%"><img src="../assets/home/contact/icon.png">創新轉譯農學研究計畫辦公室</div>-->
<!--        <div>Innovative Translational Agricultural Research Administrative Office</div>-->
<!--        <div>115201 臺北市南港區研究院路2段128號農科大樓A233室</div>-->
<!--        <div>TEL : 02-2787-2129 (代表號)</div>-->
<!--        <div>FAX : 02-2789-2477</div>-->


        <a href = "mailto: asicst@gate.sinica.edu.tw" class="contact-us-btn" s>
          <div>CONTACT</div> <div>聯絡我們</div>
        </a>

      </div>
      <div class="map-div" style="">
        <div style="position:relative; width: 100%; height: 100%; overflow: hidden">
          <img src="../assets/home/contact/map.png" style="opacity: 0;">
            <iframe  style="position: absolute; top: 0; width: 140%; height: 100%" id="gmap_canvas" src="https://maps.google.com/maps?q=%E4%B8%AD%E5%A4%AE%E7%A0%94%E7%A9%B6%E9%99%A2%E5%8D%97%E9%83%A8%E9%99%A2%E5%8D%80&center=22.921563391129496, 120.23277211740893&t=&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>+

        <div class="location-div" @click="routerBtn('/traffic')">
          <div class="location-frame"></div>
          <div class="content">
            <div>本中心位置</div>
            <div>中央研究院南部區</div>
            <div>711010臺南市歸仁區歸仁十三路100號研究大樓(I)之4樓</div>
            <div>Website : http://asicst.sinica.edu.tw</div>
            <div>Email : asicst@gate.sinica.edu.tw</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home/contact.css"
import router from "@/router";
import axios from "axios";
import {ref} from "vue";

export default {
  name: "ContactPage",
  mounted() {

    setTimeout(()=>{
      var loopTitleDIv = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('loop-title-img').classList.add('slide-anim');
          loopTitleDIv.disconnect();
        }
      }, { threshold: [0.1] });

      loopTitleDIv.observe(document.querySelector("#loop-title-div"));

    }, 10);

  },
  setup(){


    const textRef = ref("<p></p>");

    function routerBtn(path){
      router.push(path);
    }

    loadNews();

    function loadNews(){


      axios.get(window.phpPath+'php/contact.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);
            textRef.value = json[0].text;

          })
          .catch( (error) => console.log(error))

    }

    return{
      routerBtn,
      textRef
    }
  }
}
</script>

<style scoped>

</style>
