<template>
  <div class="application-page" id="application-page">
    <img class="bg" src="../assets/home/application/L-bg.png">

    <div class="application-wrap">
      <div class="logo-div">
        <img src="../assets/home/application/logo.png">
      </div>

      <div class="content-wrap">
        <div class="center">
          <div class="content" @click="routerBtn('/application/pre')">
            <div class="content-img" id="content-img-1">
              <div class="number"><img src="../assets/home/application/01.png"></div>
              <div class="anim-img" id="anim-img-1">
                <div class="text-div">
                  <div>前期諮詢</div>
<!--                  <div>中央研究院南部育成中心</div>-->
                </div>
              </div>

            </div>
            <div class="more-div"><div>更多</div><img src="../assets/arrow_white.png"></div>
          </div>
          <div class="content" @click="routerBtn('/application/method')">
            <div class="content-img" id="content-img-2">
              <div class="number"><img src="../assets/home/application/02.png"></div>
              <div class="anim-img" id="anim-img-2">
                <div class="text-div">
                  <div>進駐收費</div>
<!--                  <div>中央研究院南部育成中心</div>-->
                </div>
              </div>

            </div>
            <div class="more-div"><div>更多</div><img src="../assets/arrow_white.png"></div>
          </div>
          <div class="content" @click="routerBtn('/application/process')">
            <div class="content-img" id="content-img-3">
              <div class="number"><img src="../assets/home/application/03.png"></div>
              <div class="anim-img" id="anim-img-3">
                <div class="text-div">
                  <div>申請流程</div>
<!--                  <div>中央研究院南部育成中心</div>-->
                </div>
              </div>

            </div>
            <div class="more-div"><div>更多</div><img src="../assets/arrow_white.png"></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import "@/assets/css/home/application.css"
import {reactive, ref} from "vue";
import router from "@/router";

export default {
  name: "ApplicationPage",
  mounted() {

    setTimeout(()=>{
      var contentImg1 = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById("anim-img-1").classList.add('fade-in');
          contentImg1.disconnect();
        }
      }, { threshold: [0.5] });
      contentImg1.observe(document.querySelector("#anim-img-1"));

      var contentImg2 = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById("anim-img-2").classList.add('fade-in');

          contentImg2.disconnect();
        }
      }, { threshold: [0.5] });
      contentImg2.observe(document.querySelector("#anim-img-2"));

      var contentImg3 = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById("anim-img-3").classList.add('fade-in');

          contentImg3.disconnect();
        }
      }, { threshold: [0.5] });
      contentImg3.observe(document.querySelector("#anim-img-3"));
    }, 10);


  },
  setup() {
    window.scrollTo(0, 0);

    function routerBtn(path) {
      router.push(path);
    }

    return{
      routerBtn,
    }
  }
}
</script>

<style scoped>

</style>
