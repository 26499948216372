<template>
  <div class="relative news-list-wrap">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div class="content-wrap">
      <div class="content-div">
        <div class="director-div">
          <div @click="routerBtn('/news-event-list')">消息與活動</div>
          <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
          <div>最新消息</div>
        </div>
        <div class="title" >News</div>
        <div class="sub-div">
          <div class="subtitle">最新消息</div>
          <div class="date"></div>
        </div>
        <div class="border"></div>

        <div class="content-wrap">
          <div class="news-div" v-for="content in newsContent" :key="content" @click="routerBtn('/news/'+content[0])">
            <div class="news-wrap">
              <div class="date"><div class="new-div"><img  src="../assets/news_list/new.png" :class="content[4]"></div>{{ content[3] }}</div>
              <div>
                <div class="title">{{ content[1] }}</div>
                <div class="subtitle">{{ content[2] }}</div>
              </div>
              <div class="arrow" ><img src="../assets/news_list/arrow.png"></div>
            </div>

            <div class="border"></div>
          </div>
      </div>

        <div class="page-wrap">
          <div>Page</div>
          <div class="page-num-div">{{ curPageText }}</div>
          <div>Of {{ pageAllText }}</div>
          <div class="arrow" @click="updateData(-1)"> <img src="../assets/news_list/arrow_white.png"> </div>
          <div class="arrow" @click="updateData(1)"> <img src="../assets/news_list/arrow_white.png"></div>
        </div>
      </div>
    </div>


    <div class="footer"></div>

    <menu-bar></menu-bar>
  </div>
</template>

<script>
import "@/assets/css/news_list/news-list.css"
import MenuBar from "@/components/MenuBar";
import router from "@/router";
import axios from "axios";
import {reactive, ref} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";

export default {
  name: "NewsView",
  components:{
    MenuBar,
    ToolbarComponent,
  },
  setup(){
    window.scrollTo(0,0);

    const allContent = reactive([[]]);
    const newsContent = reactive([]);
    const pageAllText = ref("");
    const curPageText = ref("");

    let curIndex = 0;;

    function routerBtn(path){
      router.push(path);
    }

    loadNews();

    function loadNews(){

      axios.get(window.phpPath+'php/news.php?func=query-news')
          .then( (response) => {
            //console.log(response.request.responseText);

            let curIndex = 0;
            let curCount = 0;
            let json = JSON.parse(response.request.responseText);
            for (var i=0; i< json.length; i++){

              let id = json[i].main_id;
              let title = json[i].title;
              let subtitle = json[i].subtitle;
              let date = json[i].date;
              let newHidden = (json[i].new==0) ? "hidden":"";

              if(curCount >= 6){
                curCount = 0;
                allContent.push([]);
                curIndex++;
              }
              let array = [id, title, subtitle, date, newHidden];
              allContent[curIndex].push(array);
              curCount++;
            }

            pageAllText.value = "" + allContent.length;

            updateData(0);
          })
          .catch( (error) => console.log(error))

    }

    function updateData(count){
      curIndex += count;
      curIndex = Math.min(Math.max(curIndex, 0), allContent.length-1);

      newsContent.splice(0, newsContent.length);

      for (var i=0; i<allContent[curIndex].length; i++){
        newsContent.push(allContent[curIndex][i]);
      }

      curPageText.value = (curIndex+1).toString().padStart(2, '0');
    }


    return{
      routerBtn,
      newsContent,
      updateData,
      pageAllText,
      curPageText,
    }
  }
}
</script>

<style scoped>


</style>
