<template>
  <div class="manage-page" id="manage-page" >

    <div style="width: 100%; display: flex; justify-content: center">
      <div class="edit-page" id="edit-page">
        <div class="title" >收費辦法</div>
        <div class="flex-wrap">
          <div class="text" v-if="showText">內文
<!--            <textarea rows="5" class="input-box" id="text-input" type="text" v-model="text"></textarea>-->
            <Tinymce class="input-box"  v-model:value="text"  ref="textHtml"/></div>

          <div class="text" >是否在內文顯示圖片<input type="checkbox" id="new-input" v-model="img_display"></div>
          <div class="input-file-div">圖片
            <div><input class="input-file-box" type="file" @change="handleUploadFile" :value="uploadImgRef"></div>
            <div class="preview-div"><img :src="previewImgRef" v-if="previewImgRef != null"></div>
          </div>
          <div class="btn-wrap" style="justify-content: center">
            <div class="send-btn" @click="updateBtn">修改</div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div v-if="uploading">
    <loading-component></loading-component>
  </div>


</template>

<script>
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import LoadingComponent from "@/components/manage/LoadingComponent";
import Tinymce from "@/components/Tinymce.vue";
//import tinymce from "tinymce";

export default {
  name: "NewsManage",
  props:{
    titleProp:String,
  },
  components:{
    LoadingComponent,
    Tinymce
  },
  setup(props){

    const  text = ref("");
    const  img_display = ref(false);
    const  uploadImgRef = ref(null);
    const  previewImgRef = ref(null);
    const showText = ref(false);

    const uploading = ref(false);

    let uploadFile = null;

    const textHtml = ref();

    loadNews()

    function loadNews(){


      axios.get(window.phpPath+'php/charge.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);


            text.value = json[0].text;
            img_display.value = (json[0].img_display==1);
            previewImgRef.value = (json[0].img_name == "") ? "" : window.phpPath + "php/upload/"+json[0].img_name;


            showText.value = true;
          })
          .catch( (error) => console.log(error))

    }

    function handleUploadFile(e){
      uploadFile = e.target.files[0];
      previewImgRef.value = URL.createObjectURL(e.target.files[0]);
    }

    async function updateBtn(){
      uploading.value = true;

      await nextTick();

      if(uploadFile != null){
        let formData = new FormData();
        formData.append("file", uploadFile);
        axios.post(window.phpPath+'php/upload.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response)=>{
          //console.log(response.request.responseText);
          updateNews(response.request.responseText);
        }).catch((e)=>{
          console.log(e)
        });
      }else{
        updateNews("");
      }
    }

    function updateNews(filename){

      let text = textHtml.value.content;

      axios.post(window.phpPath + 'php/charge.php?func=update', {
        text: text,
        img_name: filename,
        img_display: (img_display.value==false ? 0 : 1)
      })
          .then((response) => {
            //console.log(response)
            uploading.value = false;
            alert("修改成功")
          })
          .catch((error) => console.log(error))

    }


    return{
      text,
      handleUploadFile,
      uploadImgRef,
      previewImgRef,
      uploading,
      textHtml,
      img_display,
      showText,
      updateBtn
    }
  },
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.add-btn{
  border-radius: 5px;
  border: 1px solid #00ACA0;
  background: #00ACA0;
  color: white;
  padding: 1% 1% 1% 1%;
  font-size: 1.1vw;
  margin-left: 3%;
  margin-top: -1%;
  cursor: pointer;
}

.add-btn:hover{
  background: #008980;
}


.flex-wrap{
  width: 80%!important;
}

.btn-wrap{
  width: 60%!important;
}
</style>
