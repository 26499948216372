<template>
  <router-view/>
</template>

<script>



import axios from "axios";

export default {
  mounted() {
    document.title = '';

    axios.post(window.phpPath+'php/seo.php?func=query')
        .then((res)=>{

          let json = JSON.parse(res.request.responseText);

          document.title = json[0].title;
          document.querySelector('meta[name="description"]').setAttribute("content", json[0].description);
          document.querySelector('meta[name="keywords"]').setAttribute("content", json[0].keywords);
        })
        .catch((e)=>{
          console.log(e)
        })
  },
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
