import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/tailwind.css'


//window.phpPath = 'http://192.168.0.112/asicst-website/';
window.phpPath = './';

window.anchor = '';
window.dataPage = '';
window.managePage = '';

const app = createApp(App);

app.use(store).use(router).mount('#app');

