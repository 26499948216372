<template>
  <div class="relative traffic-page">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div class="content-wrap">
      <div class="content-div">
        <div class="director-div">
          <div @click="routerBtn('/')">關於我們</div>
          <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
          <div style="color: #3B3938">交通資訊</div>
        </div>
        <div class="title" >Traffic Information</div>
        <div class="sub-div">
          <div class="subtitle">交通資訊</div>
          <div class="date"></div>
        </div>
        <div class="border"></div>
        <div class="content-text-div">
          <div class="title">交通資訊</div>
          <div class="subtitle">行車路線:(北上)</div>
          <div>中山高速公路(國道一號)</div>
          <div class="flex-content">
            <div class="line">
              <div>86快速道路交流道</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div><span class="font-bold">右轉</span>往歸仁(高鐵站)方向行駛</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>86快速道路上崙交流道下</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>左轉</div>
            </div>
            <div class="line">
              <div>「中正南路二段」</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>左轉「高鐵大道」至右向底</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>左轉「歸仁十三路」</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>中央研究院南部院區</div>
            </div>
          </div>

          <div class="subtitle">行車路線:(南下)</div>
          <div>中山高速公路(國道一號)</div>
          <div class="flex-content">
            <div class="line">
              <div>86快速道路交流道</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div><span  class="font-bold">左轉</span>往歸仁(高鐵站)方向行駛</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>86快速道路上崙交流道下</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>左轉</div>
            </div>
            <div class="line">
              <div>「中正南路二段」</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>左轉「高鐵大道」至右向底</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>左轉「歸仁十三路」</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>中央研究院南部院區</div>
            </div>
          </div>

          <div class="subtitle">乘車指南:</div>
          <div class="flex-content">
            <div class="line">
              <div>高鐵台南站或台鐵沙崙站</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>歸仁大道</div>
              <div class="arrow"><img src="../assets/traffic/arrow.png"></div>
              <div>左轉歸仁十三路，步行約5分鐘，即可到達。</div>
            </div>
          </div>


          <div class="subtitle">停車資訊:</div>
          <div>停車場入口位於歸仁十三路。</div>

          <div class="subtitle">南部院區Google Maps:</div>
          <div>點選前往Google map</div>
          <a target="_blank" href="https://www.google.com/maps?ll=22.920108,120.293175&z=15&t=m&hl=zh-TW&gl=US&mapclient=embed&cid=648727900596324566">
            <img style="width: 16.5%" class="cursor-pointer" src="../assets/traffic/googlemap.png">
          </a>
        </div>

      </div>
    </div>


    <div class="footer"></div>

    <menu-bar></menu-bar>
  </div>
</template>

<script>
import MenuBar from "@/components/MenuBar";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";
import axios from "axios";
import {ref} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";

export default {
  name: "NewsView",
  components:{
    MenuBar,
    ToolbarComponent,
  },
  setup(){
    window.scrollTo(0,0);

    const titleRef = ref("");
    const subtitleRef = ref("");
    const dateRef = ref("");
    const textRef = ref("");
    const imgRef = ref("");

    function routerBtn(path){
      router.push(path);
    }

    loadNews();

    function loadNews(){

      const route = useRoute()

      axios.post(window.phpPath+'php/news.php?func=query-one',{
        id:route.params.id
      })
          .then( (response) => {
            //console.log(response.request.responseText);

            let json = JSON.parse(response.request.responseText);

            let title = json[0].title;
            let subtitle = json[0].subtitle;
            let date = json[0].date;
            let text = json[0].text;
            let img_name = (json[0].img_name == "") ? "" : window.phpPath + "php/upload/" + json[0].img_name;

            titleRef.value = title;
            subtitleRef.value = subtitle;
            dateRef.value = date;
            textRef.value = text;
            imgRef.value = img_name;

          })
          .catch( (error) => console.log(error))

    }

    return{
      routerBtn,
      titleRef,
      subtitleRef,
      dateRef,
      textRef,
      imgRef,
    }
  }
}
</script>

<style scoped src="@/assets/css/traffic.css">


</style>
