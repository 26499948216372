<template>
  <div class="news-page" id="news-page">
    <img class="bg" src="../assets/home/news/L-bg.png">
    <img class="sub-logo" src="../assets/home/news/sub_logo.png">
    <div class="wrap" id="test">
      <img class="logo" id="logo" src="../assets/home/news/newsandeventlogo.png">
      <div class="content-wrap" >
        <div class="top">
          <div class="title-div" >
            <div>中央研究院南部育成中心</div>
            <div>活動資訊</div>
          </div>
          <div class="function-div" >
            <div class="arrow-div" @click="leftArrowBtn" ><img src="../assets/arrow_white.png"> </div>
            <div class="arrow-div" @click="rightArrowBtn" ><img src="../assets/arrow_white.png"> </div>
            <div class="more-div" @click="routerBtn('/news-event-list')"><div>更多</div><img src="../assets/arrow_white.png"></div>
          </div>
        </div>
        <div class="down-group-div" id="down-group-div">
          <div id="down-1" class="down">
            <div class="news-div" v-for="(content, index) in down1Content" :key="content" :style="index==0 ? '' : 'margin-left:2.5%'" @click="routerBtn('/news/'+content[0])">
              <div class="news-wrap">
                <div class="date">{{ content[2] }}</div>
                <div class="img"><img :src="content[3]" v-if="content[3]!=''"> </div>
                <div class="title">{{ content[1] }}</div>
              </div>
            </div>
          </div>
          <div id="down-2" class="down" style="margin-left: 100%">
            <div class="news-div" v-for="(content, index) in down2Content" :key="content" :style="index==0 ? '' : 'margin-left:2.5%'" @click="routerBtn('/news/'+content[0]) ">
              <div class="news-wrap">
                <div class="date">{{ content[2] }}</div>
                <div class="img"><img :src="content[3]" v-if="content[3]!=''"> </div>
                <div class="title">{{ content[1] }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home/news.css"
import router from "@/router";
import {reactive} from "vue";
import axios from "axios";

export default {
  name: "NewsPage",
  mounted() {

    setTimeout(()=>{
      var news = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('logo').classList.add('fade-anim');
          news.disconnect();
        }
      }, { threshold: [0.1] });

      news.observe(document.querySelector("#logo"));

      var down = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          document.getElementById('down-group-div').classList.add('down-slide');
          down.disconnect();
        }
      }, { threshold: [0] });

      down.observe(document.querySelector("#down-group-div"));
    }, 10);



  },
  setup(){

    let curDown = 1;
    let sliding = false;

    const allContent = reactive([[]]);
    const newsContent = reactive([]);
    const down1Content = reactive([]);
    const down2Content = reactive([]);
    let curIndex = 0;;

    function routerBtn(path){
      router.push(path);
    }

    loadNews();

    function leftArrowBtn(){
      if(sliding || curIndex==0){
        return;
      }
      let nextDown = (curDown+1) == 2 ? 2 : 1;
      document.getElementById('down-'+curDown).style = "transition:none; opacity:1; margin-left:0%;";
      document.getElementById('down-'+nextDown).style = "transition:none; margin-left:-100%";
      sliding = true;

      setTimeout(()=>{
        document.getElementById('down-'+curDown).style = "transition: margin-left cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s, opacity linear 0.5s;  opacity:0; margin-left:100%;";
        document.getElementById('down-'+nextDown).style = "transition: margin-left cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s; margin-left:0%";
        curDown = nextDown;
        sliding = false;

        updateData(-1);
        if(nextDown == 2){
          down2Content.splice(0, down2Content.length);
          for (var i=0; i<newsContent.length; i++)
            down2Content.push(newsContent[i]);
        }else{
          down1Content.splice(0, down1Content.length);
          for (var i=0; i<newsContent.length; i++)
            down1Content.push(newsContent[i]);
        }

      }, 10);
    }

    function rightArrowBtn(){
      if(sliding || curIndex >= allContent.length-1){
        return;
      }

      let nextDown = (curDown+1) == 2 ? 2 : 1;
      document.getElementById('down-'+curDown).style = "transition:none; opacity:1; margin-left:0%;";
      document.getElementById('down-'+nextDown).style = "transition:none; margin-left:100%";
      sliding = true;

      setTimeout(()=>{
        document.getElementById('down-'+curDown).style = "transition: margin-left cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s, opacity linear 0.5s; opacity:0; margin-left:-100%;";
        document.getElementById('down-'+nextDown).style = "transition: margin-left cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s; margin-left:0%";
        curDown = nextDown;
        sliding = false;

        updateData(1);
        if(nextDown == 2){
          down2Content.splice(0, down2Content.length);
          for (var i=0; i<newsContent.length; i++)
            down2Content.push(newsContent[i]);
        }else{
          down1Content.splice(0, down1Content.length);
          for (var i=0; i<newsContent.length; i++)
            down1Content.push(newsContent[i]);
        }


      }, 10);
    }

    function loadNews(){

      let api = 'php/news.php?func=query-no-news';

      axios.get(window.phpPath+api)
          .then( (response) => {
            //console.log(response.request.responseText);

            allContent.splice(0, allContent.length);
            allContent.push([]);

            let curIndex = 0;
            let curCount = 0;
            let json = JSON.parse(response.request.responseText);
            for (var i=0; i< json.length; i++){

              let id = json[i].main_id;
              let title = json[i].title;
              let date = json[i].date.replaceAll('-','.');
              let img_name = (json[i].img_name == "") ? "" : window.phpPath + "php/upload/" + json[i].img_name;

              if(curCount >= 3){
                curCount = 0;
                allContent.push([]);
                curIndex++;
              }
              let array = [id, title, date, img_name];
              allContent[curIndex].push(array);
              curCount++;
            }
            updateData(0);

            down1Content.splice(0, down1Content.length);
            for (var i=0; i<newsContent.length; i++)
              down1Content.push(newsContent[i]);

          })
          .catch( (error) => console.log(error))

    }

    function updateData(count){
      curIndex += count;
      curIndex = Math.min(Math.max(curIndex, 0), allContent.length-1);

      newsContent.splice(0, newsContent.length);

      for (var i=0; i<allContent[curIndex].length; i++){
        newsContent.push(allContent[curIndex][i]);
      }
    }


    return{
      routerBtn,
      leftArrowBtn,
      rightArrowBtn,
      newsContent,
      down1Content,
      down2Content,
    }
  }

}
</script>

<style scoped>



</style>
