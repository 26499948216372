<template>
<div>

</div>
</template>

<script>
import {useRoute} from "vue-router/dist/vue-router";
import router from "@/router";

export default {
  name: "RedirectView",
  setup(){
    const route = useRoute();

    switch (route.params.next){
      case 'home':
        router.push('/');
        break;
      case 'pre':
        router.push('/application/pre');
        break;
      case 'method':
        router.push('/application/method');
        break;
      case 'process':
        router.push('/application/process');
        break;
      default:
        router.push('/'+route.params.next);
        break;
    }

  }
}
</script>

<style scoped>

</style>
