<template>
  <div class="manage-page" id="manage-page" >

    <div  v-if="state == 'list'" style="width: 100%;">
      <div class="title">首頁輪播圖</div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        <div class="create-new-btn" @click="createBtn">建立輪播圖片</div>
        <table id="table">
          <tbody id="manage-tbody">
          <tr class="table-title">
            <td>編號</td>
            <td>圖片</td>
            <td>編輯</td>
            <td>刪除</td>
          </tr>

          <tr v-for="(content, index) in tableContent" :key="content">
            <!--        v-for="data in content" :key="data"-->
            <td>{{ index+1 }}</td>
            <td><img style="height: 10vw" :src="content[1]"></td>
            <td :id="'edit-'+content[0]" class="edit-btn" @click="editBtn(index, content[0])">編輯</td>
            <td :id="'delete-'+content[0]" class="edit-btn" @click="deleteBtn(content[0])">刪除</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div v-if="state == 'edit' || state == 'create'" style="width: 100%; display: flex; justify-content: center">
      <div class="edit-page" id="edit-page">
        <div class="title">更新輪播圖</div>
        <div class="flex-wrap">
          <div class="input-file-div">圖片
            <div><input class="input-file-box" type="file" @change="handleUploadFile" :value="uploadImgRef"></div>
            <div class="preview-div"><img :src="previewImgRef" v-if="previewImgRef != null"></div>
          </div>
          <div class="btn-wrap">
            <div class="back-btn" @click="backBtn">返回</div>
            <div class="send-btn" @click="updateBtn()" v-if="state == 'edit' ">修改</div>
            <div class="send-btn" @click="updateBtn()" v-if="state == 'create' ">建立</div>

          </div>
        </div>
      </div>
    </div>

  </div>

  <div v-if="uploading">
    <loading-component></loading-component>
  </div>

</template>

<script>
import {nextTick, reactive, ref} from "vue";
import axios from "axios";
import LoadingComponent from "@/components/manage/LoadingComponent";

export default {
  name: "MainManage",
  props:{
    titleProp:String,
  },
  components:{
    LoadingComponent
  },
  setup(props){

    const tableContent = reactive([[]]);
    const  state = ref("list");
    const  uploadImgRef = ref(null);
    const  previewImgRef = ref(null);
    const uploading = ref(false);


    let curUpdateId = 0;
    let uploadFile = null;

    loadNews();

    function loadNews(){

      axios.get(window.phpPath+'php/main.php?func=query')
          .then( (response) => {
            //console.log(response.request.responseText);

            tableContent.splice(0, tableContent.length);

            let json = JSON.parse(response.request.responseText);
            for (var i=0; i < json.length; i++) {
              let id = json[i].id;
              let img_name = window.phpPath+'php/upload/'+json[i].img_name;

              let array = [id, img_name];
              tableContent.push(array);
            }


          })
          .catch( (error) => console.log(error))

    }

    function editBtn(index, id){
      uploadImgRef.value = null;
      uploadFile = null;

      state.value = "edit";

      previewImgRef.value = tableContent[index][1];

      curUpdateId = id;
    }

    function backBtn(){
      state.value = "list";

      loadNews();
    }

    function handleUploadFile(e){
      uploadFile = e.target.files[0];
      previewImgRef.value = URL.createObjectURL(e.target.files[0]);
    }

    function updateBtn(){
      uploading.value = true;

      if(uploadFile != null){
        let formData = new FormData();
        formData.append("file", uploadFile);
        axios.post(window.phpPath+'php/upload.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response)=>{
          console.log(response.request.responseText);
          if(response.request.responseText == 'type'){
            alert("上傳檔案類型錯誤");
            uploading.value = false;
            return;
          }else{
            updateNews(response.request.responseText);
          }
        }).catch((e)=>{
          console.log(e)
          alert(e.response);
        });
      }else{
        alert('請選擇一張圖片。');
        uploading.value = false;
      }
    }

    function updateNews(filename){

      if(state.value == "edit") {
        axios.post(window.phpPath + 'php/main.php?func=update', {
          id: curUpdateId,
          img_name: filename
        })
            .then((response) => {
              //console.log(response.request.responseText)
              uploading.value = false;
              backBtn();
            })
            .catch((error) => {
              console.log(error)
              alert(error.response);
            })
      }

      if(state.value == "create"){
        axios.post(window.phpPath+'php/main.php?func=insert',{
          img_name:filename
        })
            .then( (response) => {
              //console.log(response)
              uploading.value = false;
              backBtn();
            })
            .catch( (error) => {
              console.log(error)
              alert(error.response);
            })
      }
    }

    function createBtn(){
      uploadImgRef.value = null;
      uploadFile = null;
      previewImgRef.value = null;
      state.value = "create";
    }

    function deleteBtn(id){
      if (window.confirm("確定要刪除嗎?")) {
        axios.post(window.phpPath+'php/main.php?func=delete',{
          id:id,
        })
            .then( (response) => {
              //console.log(response)
              backBtn();
            })
            .catch( (error) => console.log(error))
      }
    }


    return{
      editBtn,
      backBtn,
      updateBtn,
      tableContent,
      state,
      handleUploadFile,
      uploadImgRef,
      previewImgRef,
      deleteBtn,
      createBtn,
      uploading
    }
  },
}
</script>

<style scoped src="@/assets/css/manage/manage.css">
</style>

<style scoped>
.edit-page{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
