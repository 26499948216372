<template>
  <div class="relative nurturing-page">
    <toolbar-component></toolbar-component>
    <div class="scolldown-div"><img src="../assets/news_view/scrolldown.png"></div>

    <div class="content-wrap">
      <div class="content-div">
<!--        <div class="director-wrap">-->

<!--        </div>-->

<!--        <div class="title" ><p>Nurturing</p><p>Environment</p></div>-->
<!--        <div class="border"></div>-->

        <div class="loop-image-div" id="loop-image-div-1">
          <div class="arrow-div">
            <div class="arrow-wrap">
              <img class="arrow" src="../assets/home/main/arrow.png" @click="leftArrowBtn">
              <img class="arrow" src="../assets/home/main/arrow.png" @click="rightArrowBtn">
            </div>

          </div>
          <div class="dot-div">
            <div>
              <div class="dot select" id="dot-1-0" @click="dotBtn(0)"></div>
              <div class="dot" id="dot-1-1" @click="dotBtn(1)"></div>
            </div>
          </div>
        </div>

        <div class="top-content">
          <div class="director-div">
            <div @click="routerBtn('/')">關於育成中心</div>
            <div class="next-div"> <img src="../assets/news_view/next.png"> </div>
            <div style="color: #3B3938">培育環境</div>
          </div>
          <div class="title" ><p>Space</p><p style="margin-top: -5%"></p></div>
          <div class="content" >育成環境包含培育空間、一般辦公空間與儀器室，皆設有24小時警衛與門禁。</div>
          <div class="border"></div>
        </div>


        <div class="right-loop">
          <div class="loop-image-div" id="loop-image-div-2">
            <div class="arrow-div">
              <div class="arrow-wrap">
                <img class="arrow" src="../assets/home/main/arrow.png" @click="leftArrowBtn2">
                <img class="arrow" src="../assets/home/main/arrow.png" @click="rightArrowBtn2">
              </div>

            </div>
            <div class="dot-div">
              <div>
                <div class="dot select" id="dot-2-0" @click="dotBtn2(0)"></div>
                <div class="dot" id="dot-2-1" @click="dotBtn2(1)"></div>
                <div class="dot" id="dot-2-2" @click="dotBtn2(2)"></div>
                <div class="dot" id="dot-2-3" @click="dotBtn2(3)"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="down-content">
          <div class="title" >休憩區 & 公共區域</div>
          <div class="border"></div>
          <div class="content" >採開放式，廠商和來賓皆可使用。</div>
        </div>

        <div class="white-div">
          <div>生活機能</div>
          <div></div>
          <div>鄰近高鐵台南站、台鐵沙崙站、沙崙綠能科技示範場、陽明交通大學台南校區、大臺南會展中心與台糖智慧綠能出租住宅等。</div>
        </div>
      </div>
    </div>


    <div class="footer"></div>

    <menu-bar></menu-bar>
  </div>
</template>

<script>
import "@/assets/css/nurturing-environment.css"

import MenuBar from "@/components/MenuBar";
import router from "@/router";
import {useRoute} from "vue-router/dist/vue-router";
import axios from "axios";
import {ref} from "vue";
import ToolbarComponent from "@/components/ToolbarComponent";

export default {
  name: "NurturingEnvironmentView",
  components:{
    MenuBar,
    ToolbarComponent,
  },
  setup(){
    window.scrollTo(0,0);

    let index = 0;
    let dotCount = 2;
    let index2 = 0;
    let dotCount2 = 4;
    let timeout = 5000;
    let timeoutId1 = null;
    let timeoutId2 = null;


    let animId = null;
    let animId2 = null;

    clearInterval();

    // animId = setInterval(animation,timeout);
    // animId2 = setInterval(animation2,timeout);

    function animation(){
      index++;
      if(index > (dotCount-1)){
        index=0;
      }

      changeDot(index, 1);
    }

    function animation2(){
      index2++;
      if(index2 > (dotCount2-1)){
        index2=0;
      }

      changeDot(index2, 2);
    }

    function changeDot(num, id) {

      let timeoutId = (id==1) ? timeoutId1 : timeoutId2;

      clearTimeout(timeoutId)

      let path = (id==1) ? "a-"+(index+1) : "b-"+(index2+1);
      let count =  (id==1) ? dotCount: dotCount2;

      document.getElementById('loop-image-div-'+id).classList.add("loop-image-fade-out");
      document.getElementById('loop-image-div-'+id).classList.remove("loop-image-fade-in");

      for (var i = 0; i < count; i++)
        document.getElementById("dot-" + id + "-" + i).classList.remove("select");

      document.getElementById("dot-" + id + "-" + num).classList.add("select");

      /*測試*/
      document.getElementById('loop-image-div-'+id).style.backgroundImage='url('+ getImgUrl(path) +')';

      timeoutId = setTimeout(function (){
        document.getElementById('loop-image-div-'+id).style.backgroundImage='url('+ getImgUrl(path) +')';
        document.getElementById('loop-image-div-'+id).classList.add("loop-image-fade-in");
        document.getElementById('loop-image-div-'+id).classList.remove("loop-image-fade-out");
      }, 150);
    }

    function clearInterval(){
      //刪除所有SetInterval
      var interval_id = window.setInterval(()=>{}, 99999);
      for (var i = 0; i < interval_id; i++)
        window.clearInterval(i);

      window.clearInterval(animId)
      animId = setInterval(animation,timeout);
      window.clearInterval(animId2)
      animId2 = setInterval(animation2,timeout);
    }

    function rightArrowBtn(){
      index++;
      if(index > (dotCount-1)){
        index = 0;
      }
      clearInterval();
      changeDot(index, 1);


    }

    function leftArrowBtn(){
      index--;

      if(index < 0){
        index = dotCount-1;
      }
      clearInterval();
      changeDot(index, 1);


    }

    function dotBtn(dotIndex){
      index = dotIndex;

      clearInterval();
      changeDot(index, 1);
    }

    function rightArrowBtn2(){
      index2++;
      if(index2 > (dotCount2-1)){
        index2 = 0;
      }
      clearInterval();
      changeDot(index2, 2);


    }

    function leftArrowBtn2(){
      index2--;

      if(index2 < 0){
        index2 = dotCount2-1;
      }
      clearInterval();
      changeDot(index2, 2);


    }

    function dotBtn2(dotIndex){
      index2 = dotIndex;
      clearInterval();

      changeDot(index2, 2);
    }

    function getImgUrl(name) {
      var images = require.context('../assets/nurturing-environment/', false, /\.jpg$/)
      return images('./' + name + ".jpg")
    }

    function routerBtn(path){
      router.push(path);
    }


    return{
      leftArrowBtn,
      rightArrowBtn,
      dotBtn,
      leftArrowBtn2,
      rightArrowBtn2,
      dotBtn2,
      routerBtn,
    }
  }
}
</script>

<style scoped>


</style>
